import { ADD_USER, REMOVE_USER } from "../../config/constants";

export const user = (state = {}, { type, payload }: any) => {
  switch (type) {
    case ADD_USER:
      return (state = { ...payload });
    case REMOVE_USER:
      return (state = {});
    default:
      return state;
  }
};
