import React from "react";
import { ITrainingCard } from "../../utils/types";
import { fadeInUp } from "react-animations";
import { StyleSheet, css } from "aphrodite";

const TrainingCard = ({ idx, data, handleDetails }: ITrainingCard) => {
  const styles = StyleSheet.create({
    fadeInUp: {
      animationName: fadeInUp,
      animationDuration: `${
        idx === 0
          ? 0.5
          : idx === 1
          ? 0.6
          : idx === 2
          ? 0.7
          : idx === 3
          ? 0.8
          : idx === 4
          ? 0.9
          : idx === 5
          ? 1
          : idx === 6
          ? 1.1
          : idx === 7
          ? 1.2
          : idx === 8
          ? 1.3
          : 1.4
      }s`,
    },
  });

  return (
    <div
      onClick={() => handleDetails(data)}
      className={`item-card-container ${css(styles.fadeInUp)}`}
    >
      <h3>{data.name || "Title Not Found!"}</h3>
      <p>{data.description || "Description Not Found!"}</p>
    </div>
  );
};

export default TrainingCard;
