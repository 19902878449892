import React from "react";
import { locale } from "../../utils/locale";
import statistics from "../../assets/images/statistics.png";
import { useSelector } from "react-redux";
import CountUp from "react-countup";

const Statistics = () => {
  const currentLang = useSelector((state: any) => state.currentLanguage);
  return (
    <section
      className="module bg-dark-60 parallax-bg"
      data-background={statistics}
      style={{ backgroundImage: `url(${statistics})` }}
    >
      <div
        style={{
          margin: "auto",
        }}
        className="container"
      >
        <div className="row multi-columns-row">
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="count-item mb-sm-40">
              <div className="count-icon">
                <span className="icon-target"></span>
              </div>
              <h3 className="count-to font-alt">
                <CountUp end={500} duration={5} />
              </h3>
              <h5 className="count-title font-serif">
                {locale.statistics1[currentLang]}
              </h5>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="count-item mb-sm-40">
              <div className="count-icon">
                <span className="icon-bargraph"></span>
              </div>
              <h3 className="count-to font-alt">
                <CountUp end={30} duration={5} />
              </h3>
              <h5 className="count-title font-serif">
                {locale.statistics2[currentLang]}
              </h5>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="count-item mb-sm-40">
              <div className="count-icon">
                <span className="icon-happy"></span>
              </div>
              <h3 className="count-to font-alt">
                <CountUp end={10000} duration={5} />
              </h3>
              <h5 className="count-title font-serif">
                {locale.statistics3[currentLang]}
              </h5>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="count-item mb-sm-40">
              <div className="count-icon">
                <span className="icon-trophy"></span>
              </div>
              <h3 className="count-to font-alt">
                <CountUp end={20} duration={5} />
              </h3>
              <h5 className="count-title font-serif">
                {locale.statistics4[currentLang]}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
