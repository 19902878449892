export const validate_billing_details = (values: any) => {
  let errors: any = {};
  if (!values.fullname) {
    errors.fullname = "*";
  } else if (!/^[a-z A-Z]+$/.test(values.fullname)) {
    errors.fullname = "Invalid";
  }

  if (!values.email) {
    errors.email = "*";
  } else if (
    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/.test(values.email)
  ) {
    errors.email = "Invalid";
  }

  if (!values.address) {
    errors.address = "*";
  }

  if (!values.city) {
    errors.city = "*";
  } else if (!/^[a-z A-Z]+$/.test(values.city)) {
    errors.city = "Invalid";
  }

  if (!values.state) {
    errors.state = "*";
  } else if (!/^[a-z A-Z]+$/.test(values.state)) {
    errors.state = "Invalid";
  }

  if (!values.zip_code) {
    errors.zip_code = "*";
  } else if (!/^[0-9]/.test(values.zip_code)) {
    errors.zip_code = "Invalid";
  }

  return errors;
};

export const validate_feedback_details = (values: any) => {
  let errors: any = {};
  if (!values.fullname) errors.fullname = "Required";

  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/.test(values.email)
  ) {
    errors.email = "Invalid";
  }

  if (!values.message) errors.message = "Required";

  return errors;
};

export const validate_request_callback = (values: any) => {
  let errors: any = {};
  if (!values.name) {
    errors.name = "Required";
  } else if (!/^[A-Za-z ]+$/.test(values.name)) {
    errors.name = "Invalid";
  }

  if (!values.phone) {
    errors.phone = "Required";
  }

  if (values.subject === "subject") {
    errors.subject = "Required";
  }

  if (values.consent === false) {
    errors.consent = "we need your consent to call you";
  }

  return errors;
};
