import React from "react";
import { useSelector } from "react-redux";
import { locale } from "../../utils/locale";
import practice_bg from "../../assets/images/practice.png";
import bg from "../../assets/images/bg.svg";

const Service = () => {
  const currentLang = useSelector((state: any) => state.currentLanguage);

  return (
    <div className="service-wrapper">
      <img src={practice_bg} />
      <div className="service-content" style={{ background: `url(${bg})` }}>
        <h2 className="module-title font-alt align-center">
          {locale.practiceArea[currentLang]}
        </h2>
        <div className="vertical-line"></div>
        <div className="service-list">
          <div className="item-left">
            <span className="icon-briefcase"></span>
            <div className="content">
              <h3 className="alt-features-title font-alt">
                Artificial Intelligence
              </h3>
              {locale.practiceAreaDetail1[currentLang]}
            </div>
          </div>
          <div className="item-right">
            <span className="icon-wallet"></span>
            <div className="content">
              <h3 className="alt-features-title font-alt">Data Engineering</h3>
              {locale.practiceAreaDetail4[currentLang]}
            </div>
          </div>
          <div className="item-left">
            <span className="icon-puzzle"></span>
            <div className="content">
              <h3 className="alt-features-title font-alt">Machine Learning</h3>
              {locale.practiceAreaDetail2[currentLang]}
            </div>
          </div>
          <div className="item-right">
            <span className="icon-laptop"></span>
            <div className="content">
              <h3 className="alt-features-title font-alt">
                Internet of Things
              </h3>
              {locale.practiceAreaDetail5[currentLang]}
            </div>
          </div>
          <div className="item-left">
            <span className="icon-pricetags"></span>
            <div className="content">
              <h3 className="alt-features-title font-alt">Data Platforms</h3>
              {locale.practiceAreaDetail3[currentLang]}
            </div>
          </div>
          <div className="item-right">
            <span className="icon-linegraph"></span>
            <div className="content">
              <h3 className="alt-features-title font-alt">Cloud Computing</h3>
              {locale.practiceAreaDetail6[currentLang]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
