import React from "react";
import bg from "../../assets/images/bg.svg";

const Clients = () => {
  return (
   <div className="testmoninal-wrapper" style={{ background: `url(${bg})` }}>
      <div className="testmoninal-content">
        <i className="fa fa-quote-left"></i>
        <p>
          I’ve known Shantanu Pandey for a large part of my career in technology
          – over ten years. Shantanu is a born leader with exceptional technical
          skills and the natural art of communicating complex material in a
          consumable manner, helping others learn what they need quickly and,
          more importantly, retain the information. I’m thrilled to know that he
          uses his unique skill set to help others train and certify on today’s
          essential technologies. I’m excited to see APT Consulting expand and
          grow into a premier resource for training and certification.
        </p>

        <div className="person">
          <h6> Michelle Fleming</h6>
          <h6> Managing Member, Facing Bias, LLC</h6>
        </div>
      </div>
    </div>
  );
};

export default Clients;
