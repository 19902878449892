import React, { useEffect, useState } from "react";
import { locale } from "../../utils/locale";
import ToggleSwitch from "./ToggleSwitch";

import { IFilters } from "../../utils/types";
import { FaFilter, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getOEM, getRoles } from "../../config/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Collapse } from "react-collapse";
import { MobileView, isMobile } from "react-device-detect";

const Filters = ({
  currentOEM,
  currentRole,
  setCurrentOEM,
  setCurrentRole,
}: IFilters) => {
  const currentLang = useSelector((state: any) => state.currentLanguage);
  const [filterTempRole, setFilterTempRole] = useState<any>();
  const [filterTempOEM, setFilterTempOEM] = useState<any>();
  const [filtersOEM, setFiltersOEM] = useState<any>([]);
  const [filtersRole, setFiltersRole] = useState<any>([]);
  const [searchWordRole, setSearchWordRole] = useState("");
  const [searchWordOEM, setSearchWordOEM] = useState("");

  const [isRoleLoading, setIsRoleLoading] = useState(false);
  const [isOEMLoading, setIsOEMLoading] = useState(false);

  const [toggleFilter, setToggleFilter] = useState(!isMobile);

  useEffect(() => {
    fetchRoles();
    fetchOEMs();
  }, []);

  const fetchRoles = async () => {
    setIsRoleLoading(true);
    await getRoles()
      .then(({ data, status }: any) => {
        if (status === 200 && data.errorMsg === "success") {
          let tempData: any = [];
          data.data.forEach((item: any) => {
            item.subCategories.forEach((sub: any) => {
              tempData.push({ name: sub.name, value: false });
            });
          });
          setFiltersRole(tempData);
          setFilterTempRole(tempData);
        }
        setIsRoleLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsRoleLoading(false);
      });
  };

  const fetchOEMs = async () => {
    setIsOEMLoading(true);
    await getOEM()
      .then(({ data, status }: any) => {
        if (status === 200 && data.errorMsg === "success") {
          let tempData: any = [];
          data.data.forEach((item: any) => {
            tempData.push({
              name: item.name,
              value: false,
              b_value: item.value,
            });
          });
          setFiltersOEM(tempData);
          setFilterTempOEM(tempData);
        }
        setIsOEMLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsOEMLoading(false);
      });
  };

  const handleRoleToggleSwitch = (name: string) => {
    let tempData: any = filtersRole;
    tempData.forEach((skill: any) => {
      if (skill.name === name) {
        skill.value = !skill.value;
        if (skill.value) {
          setCurrentRole([...currentRole, skill.name]);
        } else {
          const res = currentRole.filter((name) => name !== skill.name);
          setCurrentRole([...res]);
        }
      }
    });
    setFiltersRole([...tempData]);
  };

  const handleOEMToggleSwitch = (name: string) => {
    let tempData: any = filtersOEM;
    tempData.forEach((skill: any) => {
      if (skill.name === name) {
        skill.value = !skill.value;
        if (skill.value) {
          setCurrentOEM([...currentOEM, skill.b_value]);
        } else {
          const res = currentOEM.filter((b_value) => b_value !== skill.b_value);
          setCurrentOEM([...res]);
        }
      }
    });
    setFiltersOEM([...tempData]);
  };

  const handleClear = () => {
    let tempSkillRole: any = filtersRole;
    let tempSkillOEM: any = filtersOEM;
    tempSkillRole.forEach((skill: any) => {
      skill.value = false;
    });
    setFiltersRole([...tempSkillRole]);

    tempSkillOEM.forEach((skill: any) => {
      skill.value = false;
    });
    setFiltersOEM([...tempSkillOEM]);
    setCurrentRole([]);
    setCurrentOEM([]);
  };

  const handleSearchCaterogy = (value: string, cat: string) => {
    if (cat === "role") {
      let temp: any = [];
      const res = filtersRole.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilterTempRole([...res]);
    } else if (cat === "oem") {
      const res = filtersOEM.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilterTempOEM([...res]);
    }
  };

  return (
    <div className="left-content">
      <div className="filter-title">
        <FaFilter
          size={15}
          className="clear"
          onClick={handleClear}
          title={locale.searchClear[currentLang]}
        />
        <h4>{locale.searchFilterBy[currentLang]}</h4>
        <MobileView>
          {toggleFilter ? (
            <FaCaretUp
              size={25}
              className="caretIcon"
              onClick={() => {
                setToggleFilter(false);
              }}
            />
          ) : (
            <FaCaretDown
              size={25}
              className="caretIcon"
              onClick={() => {
                setToggleFilter(true);
              }}
            />
          )}
        </MobileView>
      </div>
      <Collapse isOpened={toggleFilter}>
        <div className="filter-groups">
          <div className="filter-group">
            <div className="filter-top">
              <h6>Roles</h6>
              <input
                disabled={isRoleLoading}
                type="text"
                value={searchWordRole}
                onChange={({ target: { value } }) => {
                  setSearchWordRole(value);
                  handleSearchCaterogy(value, "role");
                }}
              />
            </div>
            <div className="filter-items">
              {isRoleLoading ? (
                <Skeleton count={8} />
              ) : (
                <div className="filter-sub-group">
                  <div className="filter-items">
                    {filterTempRole &&
                      filterTempRole.map((item: any, index: number) => (
                        <ToggleSwitch
                          key={index}
                          item={item.name}
                          value={item.value}
                          subCat={item.name}
                          handleToggleSwitch={handleRoleToggleSwitch}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="filter-group">
            <div className="filter-top">
              <h6>OEM</h6>
              <input
                disabled={isOEMLoading}
                type="text"
                value={searchWordOEM}
                onChange={({ target: { value } }) => {
                  setSearchWordOEM(value);
                  handleSearchCaterogy(value, "oem");
                }}
              />
            </div>
            <div className="filter-items">
              {isOEMLoading ? (
                <Skeleton count={8} />
              ) : (
                filterTempOEM &&
                filterTempOEM.map((item: any, index: number) => (
                  <ToggleSwitch
                    key={index}
                    item={item.name}
                    value={item.value}
                    handleToggleSwitch={handleOEMToggleSwitch}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Filters;
