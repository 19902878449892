import React from "react";

const Marker = ({ text }: any) => (
  <div className="marker">
    <i className="fa fa-map-marker fa-2x" aria-hidden="true"></i>
    {text}
  </div>
);

export default Marker;
