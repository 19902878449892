import { ITraining } from "../../utils/types";
import {
  ADD_TO_CART,
  DELETE_ALL_CART,
  DELETE_FROM_CART,
} from "../../config/constants";

export const cartItems = (state: ITraining[] = [], { type, payload }: any) => {
  switch (type) {
    case ADD_TO_CART:
      return (state = [...state, payload]);
    case DELETE_FROM_CART:
      const res = state.filter((item: any) => item.event.id !== payload);
      return (state = [...res]);
    case DELETE_ALL_CART:
      return (state = []);
    default:
      return state;
  }
};
