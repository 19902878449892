import { ITraining } from "../../utils/types";
import {
  ADD_TO_PAY,
  DELETE_ALL_PAY,
  DELETE_FROM_PAY,
} from "../../config/constants";

export const payItems = (state: any = null, { type, payload }: any) => {
  switch (type) {
    case ADD_TO_PAY:
      return (state = { ...payload });
    case DELETE_ALL_PAY:
      return (state = null);
    default:
      return state;
  }
};
