export const AUTH = {
  AAD_B2C_Name: "aptlearn",
  AAD_B2C_CLIENT_ID: process.env.REACT_APP_AAD_B2C_CLIENT_ID,
  AAD_B2C_USER_FLOW_SIGNUPSIGNIN: "B2C_1_SIGN_IN_SIGN_UP_LOCAL",
  AAD_B2C_USER_FLOW_RESET_PASSWORD: "B2C_1_RESET_PASS",
};

export const COMPANY_ADDRESS = {
  lat: 49.03403771305022,
  lng: -122.27386946684317,
};

/*
export const COMPANY_ADDRESS_1 = {
    lat: 49.03403771305022,
    lng: -122.27386946684317,
};
export const COMPANY_ADDRESS_2 = {
    lat: 53.50928611827949,
    lng: - 113.43906865842025,
};*/
export const TIMEOUT = 10000;
export const SEND_GRID_TO = "";
export const SEND_GRID_SUBJECT = "";

export const EN_SUBJECTS = [
  { name: "Subject", value: "subject" },
  { name: "Discount for Group Booking", value: "Groupbooking" },
  {
    name: "Strategic Learning Partner for Government Body",
    value: "Government",
  },
  { name: "Strategic Learning Partner for Enterprises", value: "Enterprise" },
  { name: "Strategic Learning Partner for Start-ups", value: "Startup" },
  {
    name: "Strategic Learning Partner for Educational Institutes",
    value: "Academia",
  },
  { name: "I am a Trainer and want to train with you", value: "Trainer" },
  {
    name: "I am a Learner and want discount for multiple trainings and courses",
    value: "Discount",
  },
  { name: "Other Queries", value: "Other" },
];
export const FR_SUBJECTS = [
  { name: "Sujette", value: "subject" },
  { name: "Réduction pour réservation de groupe", value: "Groupbooking" },
  {
    name: "Partenaire d'apprentissage stratégique pour l'organisme gouvernemental",
    value: "Government",
  },
  {
    name: "Partenaire d'apprentissage stratégique pour les entreprises",
    value: "Enterprise",
  },
  {
    name: "Partenaire d'apprentissage stratégique pour les start-ups",
    value: "Startup",
  },
  {
    name: "Partenaire d'apprentissage stratégique pour les établissements d'enseignement",
    value: "Academia",
  },
  {
    name: "Je suis un entraîneur et je veux m'entraîner avec vous",
    value: "Trainer",
  },
  {
    name: "Je suis un apprenant et je souhaite une réduction pour plusieurs formations et cour",
    value: "Discount",
  },
  { name: "Autres requêtes", value: "Other" },
];

export const INIT_FEEDBACK_DETAILS = {
  fullname: "",
  email: "",
  message: "",
};

export const INIT_BILLING_DETAILS = {
  fullname: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
};

export const INIT_REQUEST_CALLBACK = {
  name: "",
  phone: "",
  subject: "subject",
  consent: false,
};

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_CART = "DELETE_ALL";
export const ADD_USER = "ADD_USER";
export const REMOVE_USER = "REMOVE_USER";
export const ADD_TO_PAY = "ADD_TO_PAY";
export const DELETE_FROM_PAY = "DELETE_FROM_PAY";
export const DELETE_ALL_PAY = "DELETE_ALL";

export const REPLY_TRAINING_ADDRESS = "training@consultingapt.com";
export const NO_REPLY_TRAINING_ADDRESS = "noreply@consultingapt.com";

export const FLAGS: any = {
  gb: { code: "🇬🇧", text: "En-GB" },
  us: { code: "🇺🇸", text: "En-US" },
  fr: { code: "🇫🇷", text: "Fr-Ca" },
};

//export const GA_TRACKING_ID = "UA-219717306-1";
export const GA_TRACKING_ID = "UA-219717306-2";
export const MAIN_URL = "https://www.consultingapt.com";
