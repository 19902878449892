import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { locale } from "../../utils/locale";

const Footer = () => {
  const { currentLanguage } = useSelector((state: any) => state);

  return (
    <div id="aboutus" className="footer-container bg-dark">
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p className="copyright font-alt">
                &copy; 2024&nbsp;APT Consulting,{" "}
                {locale.footerRight[currentLanguage]}
              </p>
            </div>
            <div className="col-sm-6">
              <div className="footer-social-links">
                <a
                  target="_blank"
                  href="https://facebook.com/APT-Consulting-106333858701428"
                >
                  <i className="fa fa-facebook fa-lg"></i>
                </a>
                <a target="_blank" href="https://twitter.com/apt_consulting">
                  <i className="fa fa-twitter fa-lg"></i>
                </a>
                <a target="_blank" href="https://instagram.com/">
                  <i className="fa fa-instagram fa-lg"></i>
                </a>
                <a
                  target="_blank"
                  href="https://linkedin.com/company/aptconsulting/"
                >
                  <i className="fa fa-linkedin fa-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <hr className="divider-d" />
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <div className="widget">
              <h5 className="widget-title font-alt">
                {locale.footerTitle1[currentLanguage]}
              </h5>
              <p>
                <a href="#">{locale.footerTitle11[currentLanguage]}</a>
              </p>
              <p>
                <a href="#">{locale.footerTitle12[currentLanguage]}</a>
              </p>
              <p>
                <a href="mailto:info@consultingapt.com">
                  {`${locale.footerTitle13[currentLanguage]}: info@consultingapt.com`}
                </a>
              </p>

                          <p>
                              <a href="tel:8883836659">
                                  {`${locale.footerTitle14[currentLanguage]}: 8883836659`}</a></p>
                          <p>
                              
                              <span dangerouslySetInnerHTML={{ __html: `Address: Unit 254, 300-34334 Forrest Terrace, Abbotsford,<br> BC, Canada V2S 1G7` }} />
                              

                           </p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="widget">
              <h5 className="widget-title font-alt">
                {locale.footerTitle2[currentLanguage]}
              </h5>
              <p>
                <Link to="/search">
                  Microsoft Azure Certified AI Engineer- Associate
                </Link>
              </p>
              <p>
                <Link to="/search">
                  Microsoft Azure Certified Data Engineer- Associate
                </Link>
              </p>
              <p>
                <Link to="/search">
                  Microsoft Azure Certified Data Scientist- Associate
                </Link>
              </p>
              <p>
                <Link to="/search"> Red Hat Certified Administrator</Link>
              </p>
              <p>
                <Link to="/search"> Red Hat Certified Developer</Link>
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="widget">
              <h5 className="widget-title font-alt">
                {locale.footerTitle3[currentLanguage]}
              </h5>
              <p>
                <Link to="/search">AI and ML</Link>
              </p>
              <p>
                <Link to="/search">Career Advice</Link>
              </p>
              <p>
                <Link to="/search">Cloud Computing</Link>
              </p>
              <p>
                <Link to="/search">Cloud Security</Link>
              </p>
              <p>
                <Link to="/search">Data Engineering</Link>
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="widget">
              <h5 className="widget-title font-alt">
                {locale.footerTitle4[currentLanguage]}
              </h5>
              <p>
                <Link to="/search">AI and ML</Link>
              </p>
              <p>
                <Link to="/search">Career Advice</Link>
              </p>
              <p>
                <Link to="/search">Cloud Computing</Link>
              </p>
              <p>
                <Link to="/search">Cloud Security</Link>
              </p>
              <p>
                <Link to="/search">Data Engineering</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
