import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { COMPANY_ADDRESS } from "../../config/constants";

const GoogleMap = () => {
    return (
        <div id="map-section">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_BOOTSTRAPURLKEYS || "",
                }}
                defaultCenter={COMPANY_ADDRESS}
                defaultZoom={14}
            >
                <Marker
                    lat={COMPANY_ADDRESS.lat}
                    lng={COMPANY_ADDRESS.lng}
                    text="APT Consulting Ltd"
                />
            </GoogleMapReact>
        </div>
    );
};

export default GoogleMap;
