import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaOpencart, FaRunning, FaCheckCircle } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import { removeAll, removeFromCart } from "../../store/actions";
import moment from "moment";
import { numberWithComma } from "../../utils/format";
import Payment from "./Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useGAEventsTracker } from "../../hooks";
import { Link } from "react-router-dom";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems: any = useSelector((state: any) => state.cartItems);
  const [sum, setSum] = useState(0);
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || "");
  const [isPaymentSucceeded, setIsPaymentSucceeded] = useState(false);
  const confirmRef: any = useRef();
  const gaEventsTrackerCourse = useGAEventsTracker("Course");

  useEffect(() => {
    let total = 0;
    cartItems.length > 0 &&
      cartItems.forEach((item: any) => {
        total += item.event.price;
      });
    setSum(total);
  }, [cartItems]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handlePaymentResult = (status: string) => {
    confirmRef.current.click();
    if (status === "success") {
      setIsPaymentSucceeded(true);
      setSum(0);
      dispatch(removeAll());
    } else if (status === "card_error") {
      toast.error("Card Error!, Please contact your card provider");
    } else if (status === "backend_error") {
      toast.error("Error!, Unable to communicate with the server");
    } else if (status === "booking_error") {
      toast.warning("Error!, Unable to send confirmation email");
    } else {
      toast.error("Error!, Please contact the administration");
    }
  };

  return (
    <div className="cart-container">
      <div className="contents">
        <Link to="/search">
          <FaRunning size={25} />
          <h5> Back To Search</h5>
        </Link>
        {isPaymentSucceeded ? (
          <div className="payment-completed-container">
            <AiFillCheckCircle />
            <h4>The payment has been successfull</h4>
            <p>
              We have sent you a confirmation email. Please check out your mail
              inbox!
            </p>
          </div>
        ) : cartItems.length === 0 ? (
          <div className="no-cart-items">
            <FaOpencart size={150} />
            <h4>There are no items on cart</h4>
          </div>
        ) : (
          <Fragment>
            <div className="contents-header">
              <div className="header-item-id">#</div>
              <div className="header-item-title">Course Name</div>
              <div className="header-item">Event</div>
              <div className="header-item">Duration</div>
              <div className="header-item">Price</div>
              <div className="header-item-delete"></div>
            </div>
            <div className="contents-body">
              {cartItems.map((item: any, index: number) => (
                <div className="contents-row" key={index}>
                  <div className="content-item-id">{index + 1}</div>
                  <div className="content-item-title">{item.course.name}</div>
                  <div className="content-item">
                    {`${moment(item.event.startdate).format(
                      "MMM DD, YYYY"
                    )} - ${moment(item.event.enddate).format("MMM DD, YYYY")}`}
                  </div>
                  <div className="content-item">{`
                  ${
                    moment
                      .duration(
                        moment(item.event.enddate, "YYYY-MM-DDThh:mm:ss").diff(
                          moment(item.event.startdate, "YYYY-MM-DDThh:mm:ss")
                        )
                      )
                      .months() > 0
                      ? moment
                          .duration(
                            moment(
                              item.event.enddate,
                              "YYYY-MM-DDThh:mm:ss"
                            ).diff(
                              moment(
                                item.event.startdate,
                                "YYYY-MM-DDThh:mm:ss"
                              )
                            )
                          )
                          .months() + " months "
                      : ""
                  } 
                    ${moment
                      .duration(
                        moment(item.event.enddate, "YYYY-MM-DDThh:mm:ss").diff(
                          moment(item.event.startdate, "YYYY-MM-DDThh:mm:ss")
                        )
                      )
                      .days()} days`}</div>
                  <div className="content-item">{`$ ${item.event.price}`}</div>
                  <div
                    className="content-item-delete"
                    onClick={() => {
                      dispatch(removeFromCart(item.event.id));
                      gaEventsTrackerCourse("Course Removed", item.course.name);
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </div>
                </div>
              ))}
              <div className="confirm-container">
                <div className="total">
                  Total &nbsp; $ {numberWithComma(sum.toString())}
                </div>
                <div
                  className="confirm-btn"
                  data-toggle="modal"
                  data-target="#confirmModal"
                  ref={confirmRef}
                >
                  <FaCheckCircle size={20} />
                  <h5> Confirm Courses</h5>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <Elements stripe={stripePromise}>
          <Payment sum={sum} handlePaymentResult={handlePaymentResult} />
        </Elements>
      </div>
    </div>
  );
};

export default Cart;
