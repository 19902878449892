import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ children }: any) => {
  const isAuthenticated = useIsAuthenticated();
  return !isAuthenticated ? <Redirect to="/" /> : children;
};

export default ProtectedRoute;
