import { CHANGE_LANGUAGE } from "../../config/constants";
import { ILangReducer } from "../../utils/types";

export const currentLanguage = (
  state: string = "gb",
  { type, payload }: ILangReducer
) => {
  switch (type) {
    case CHANGE_LANGUAGE:
      return (state = payload);
    default:
      return state;
  }
};
