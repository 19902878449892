import React, { useState } from "react";
import { numberWithComma } from "../../utils/format";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import {
  INIT_BILLING_DETAILS,
  NO_REPLY_TRAINING_ADDRESS,
} from "../../config/constants";
import { validate_billing_details } from "../../utils/validate";
import {
  getPaymentIntent,
  saveTrainingToBook,
  sendMail,
} from "../../config/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { removeAllPay } from "../../store/actions";

const Payment = ({ sum, handlePaymentResult, bulkInfo }: any) => {
  const dispatch = useDispatch();
  const { currentUser, cartItems, payItems } = useSelector(
    (state: any) => state
  );
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [isCardValidated, setIsCardValidated] = useState(false);
  const CardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#000",
        "::placeholder": {
          color: "#87bbfd",
        },
      },
      invalid: {
        color: "#FFC7EE",
      },
    },
    hidePostalCode: true,
    disabled: isPaymentLoading,
  };

  const formik = useFormik({
    initialValues: INIT_BILLING_DETAILS,
    onSubmit: (values) => {
      const billingDetails = {
        name: values.fullname,
        email: values.email,
        address: {
          city: values.city,
          line1: values.address,
          state: values.state,
          postal_code: values.zip_code,
        },
      };
      if (!isCardValidated) {
        toast.warning("Empty Information!, Please provide your card details.");
      } else {
        handlePayment(billingDetails);
      }
    },
    validate: (values) => validate_billing_details(values),
  });

  const handlePayment = async (details: any) => {
    setPaymentLoading(true);
    const {
      data: {
        data: { client_secret },
        errorMsg,
      },
    }: any = await getPaymentIntent((sum * 100).toString(), "usd").catch((_) =>
      handlePaymentResult("card_error")
    );
    if (errorMsg === "success") {
      const cardElement = elements.getElement(CardElement);
      const paymentMethodRequest = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: details,
      });

      const { paymentMethod } = paymentMethodRequest;
      const { paymentIntent } = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethod.id,
      });

      const { status } = paymentIntent;

      if (status === "succeeded") {
        // send email to the current user
        const msg = {
          subject:
            "Confirmation message for course registration with APT Consulting Ltd!",
          to: [
            {
              email: currentUser.email,
              name: currentUser.fullname,
            },
          ],
          from: {
            email: NO_REPLY_TRAINING_ADDRESS,
            name: "APT Consulting Ltd",
          },
          replyto: {
            email: "",
            name: "",
          },
          htmlcontent: `You have been register for the following courses <br/><br/>                 
         ${
           cartItems.length > 0 ? (
             <b>
               {cartItems.map(
                 (item: any) =>
                   item.course.name +
                   "/" +
                   item.event.skill.toString() +
                   " <br />"
               )}
               <br />
               Total Seat: 1
             </b>
           ) : (
             `<b> ${payItems.course.name + "/" + payItems.event.skill} </b><br/>
               Total Seat: ${payItems.details.seat}`
           )
         }
          <br/><br/>
          <small>APT Consulting Ltd, 300, 34334 Forrest Terrace Unit 254, Abbotsford, BC V2S 1G7, Canada</small>`,
        };
        await sendMail(msg)
          .then(({ data }: any) => {
            if (data.errorMsg !== "success") {
            }
            handlePaymentResult("success");
          })
          .catch((_) => {
            handlePaymentResult("success");
            handlePaymentResult("booking_error");
          })
          .finally(async () => {
            if (payItems != null) {
              const bookingData = {
                userid: currentUser.userId,
                trainingid: payItems.event.id,
                name: details.name,
                email: currentUser.email,
                address: details.address.line1,
                city: details.address.city,
                state: details.address.state,
                zip: details.address.postal_code,
                message: status,
                stripeid: paymentIntent.id,
                amount: paymentIntent.amount,
                members: `${payItems.details.email}|${payItems.details.seat}`,
                isbulk: true,
              };

              await saveTrainingToBook(bookingData)
                .then(({ data }: any) => {
                  if (data.errorMsg !== "success") {
                    toast.warning(
                      "Error while Booking the course!, Please contact the admin."
                    );
                  } else {
                    toast.success(
                      `The ${payItems.course.name} has been booked successfully!`
                    );
                  }
                })
                .catch((_) => {
                  toast.warning(
                    "Error while Booking the course!, Please contact the admin."
                  );
                });
            }
          });
      } else {
        handlePaymentResult("card_error");
      }
      dispatch(removeAllPay());
    } else {
      handlePaymentResult("backend_error");
    }
    setPaymentLoading(false);
  };

  return (
    <div id="confirmModal" className="modal fade payment-modal" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => dispatch(removeAllPay())}
            >
              &times;
            </button>
            <h4 className="modal-title">Course Payment</h4>
          </div>
          <div className="modal-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <label>Name</label>
                  {formik.errors.fullname && (
                    <div className="error">{formik.errors.fullname}</div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="Jane Doe"
                  name="fullname"
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                  disabled={isPaymentLoading}
                />
              </div>
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <label>Email</label>
                  {formik.errors.email && (
                    <div className="error">{formik.errors.email}</div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="jane.doe@example.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  disabled={isPaymentLoading}
                />
              </div>
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <label>Address</label>
                  {formik.errors.address && (
                    <div className="error">{formik.errors.address}</div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="185 Berry St. Suite 550"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  disabled={isPaymentLoading}
                />
              </div>
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <label>City</label>
                  {formik.errors.city && (
                    <div className="error">{formik.errors.city}</div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="San Francisco"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  disabled={isPaymentLoading}
                />
              </div>
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <label>State</label>
                  {formik.errors.state && (
                    <div className="error">{formik.errors.state}</div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="California"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  disabled={isPaymentLoading}
                />
              </div>
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <label>ZIP</label>
                  {formik.errors.zip_code && (
                    <div className="error">{formik.errors.zip_code}</div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="94103"
                  name="zip_code"
                  value={formik.values.zip_code}
                  onChange={formik.handleChange}
                  disabled={isPaymentLoading}
                />
              </div>
              <div className="pay-btn">
                <div className="card-element-container">
                  <CardElement
                    className="card"
                    options={CardElementOptions}
                    onChange={(e: any) => {
                      if (e.complete) {
                        setIsCardValidated(true);
                      } else {
                        setIsCardValidated(false);
                      }
                    }}
                  />
                </div>
                <button
                  className={`submitBtn ${isPaymentLoading && "processingBtn"}`}
                  disabled={isPaymentLoading}
                  type="submit"
                >
                  {isPaymentLoading
                    ? "Processing..."
                    : ` Pay $${numberWithComma(String(sum))}`}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
