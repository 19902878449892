import React, { Fragment, useEffect, useRef, useState } from "react";
import { locale } from "../../utils/locale";
import { ITrainingDetail, ITraining, IBulk } from "../../utils/types";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { toast } from "react-toastify";
import moment from "moment";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import ReactLoading from "react-loading";
import {
  addToPAY,
  addUser,
  removeAll,
  removeAllPay,
} from "../../store/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/actions";
import { getTrainingCalender, saveUserInfo } from "../../config/api";
import { useGAEventsTracker } from "../../hooks";
import _ from "lodash";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Payment from "../CartContainer/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { numberWithComma } from "../../utils/format";
import noEvent from "../../assets/images/calendar.png";

const TrainingDetails = ({ currentData, setShowDetail }: ITrainingDetail) => {
  const [data] = useState<any>(currentData);
  const [bulkBooking, setBulkBooking] = useState<IBulk>({
    email: "",
    seat: 1,
    price: 14245662,
  });
  const [isBulkEmailValid, setIsBulkEmailValid] = useState(false);
  const [bulkId, setBulkId] = useState<any>();
  const [isFetchingCalender, setIsFetchingCalender] = useState(false);
  const [events, setEvents] = useState<any>([]);
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const confirmRef: any = useRef();
  const bulkClose: any = useRef();
  const { currentUser, cartItems, currentLanguage } = useSelector(
    (state: any) => state
  );
  const gaEventsTrackerCourse = useGAEventsTracker("Course");
  const gaEventsTrackerAccount = useGAEventsTracker("Account");
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || "");
  const [price, setPrice] = useState<number>();

  useEffect(() => {
    window.scroll(0, 0);
    fetchCalender();

    return () => {
      setEvents([]);
      setIsFetchingCalender(false);
    };
  }, []);

  const addItemPayment = (id: number = 0, bulk: boolean) => {
    if (!events[id].isbooked) {
      dispatch(removeAllPay());
      dispatch(removeAll());
      if (bulk) {
        dispatch(
          addToPAY({
            course: data,
            event: events[id],
            details: bulkBooking,
            type: "bulk",
          })
        );
      } else {
        dispatch(
          addToPAY({
            course: data,
            event: events[id],
            details: {
              seat: 1,
            },
            type: "single",
          })
        );
      }
      gaEventsTrackerCourse("Course Added", data && data.name);
    }
  };

  const addItemCart = () => {
    events.forEach((ele: any) => {
      !ele.isbooked &&
        dispatch(
          addToCart({
            course: data,
            event: ele,
            type: "single",
          })
        );
    });
  };

  const handleBookNow = (id: number = 0) => {
    addItemPayment(id, false);
    setPrice(events[id].price);
  };

  const fetchCalender = async () => {
    setIsFetchingCalender(true);
    await getTrainingCalender(
      currentData ? currentData.rowKey : "",
      currentUser.userId ? currentUser.userId : ""
    )
      .then(({ data }: any) => {
        if (data.errorMsg === "success") {
          setEvents(_.orderBy(data.data, ["startdate"]));
        }
        setIsFetchingCalender(false);
      })
      .catch((_) => {
        setIsFetchingCalender(false);
      });
  };

  const checkInCart = (id: number) =>
    cartItems.filter(
      (item: any) =>
        item.type === "single" &&
        item.course.rowKey === data.rowKey &&
        item.event.id === events[id].id
    ).length > 0;

  const checkEventInCart = (id: number) =>
    cartItems.filter(
      (item: any) =>
        item.type === "bulk" &&
        item.course.rowKey === data.rowKey &&
        item.event.id === events[id].id
    ).length > 0;

  const handleInputChange = (value: string, cat: string) => {
    switch (cat) {
      case "seat":
        setBulkBooking({
          ...bulkBooking,
          seat: parseInt(value),
          price: parseFloat(events[bulkId].price) * parseInt(value),
        });
        break;
      case "email":
        setBulkBooking({ ...bulkBooking, email: value });
        if (bulkBooking.email === "") {
          setIsBulkEmailValid(false);
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/.test(value)) {
          setIsBulkEmailValid(false);
        } else {
          setIsBulkEmailValid(true);
          setBulkBooking({ ...bulkBooking, email: value });
        }
    }
  };

  const handlePaymentResult = (status: string) => {
    confirmRef.current.click();
    if (status === "success") {
      toast.success("The payment has been successfull");
      toast.info(
        " We have sent you a confirmation email. Please check out your mail inbox!"
      );
      fetchCalender();
    } else if (status === "card_error") {
      toast.error("Card Error!, Please contact your card provider");
    } else if (status === "backend_error") {
      toast.error("Error!, Unable to communicate with the server");
    } else if (status === "booking_error") {
      toast.warning("Error!, Unable to send confirmation email");
    } else {
      toast.error("Error!, Please contact the administration");
    }
  };

  return (
    <Fragment>
      <div className="training-detail-container">
        <div className="search-page-input">
          <button onClick={() => setShowDetail(false)}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
            {locale.searchBack[currentLanguage]}
          </button>
          <input
            type="text"
            value={data && data.name}
            disabled
            placeholder="Data Science, AI, Finance, Marketing, Microsoft courses, SQL, Excel ..."
          />
          <i className="fa fa-search fa-lg icon" aria-hidden="true"></i>
        </div>
        <div className="training-detail">
          <div className="traing-detail-content">
            <div className="training-left">
              <div className="item-important">
                <div className="item-important-left">
                  <h4>{locale.searchCourseCode[currentLanguage]}</h4>
                  <h5>{data.rowKey.replace(/!/g, " ") || ""}</h5>
                </div>
                <div className="item-important-middle">
                  <h4>{locale.searchCertificate[currentLanguage]}</h4>
                  <h5>{data.certification || ""}</h5>
                </div>
                <div className="item-important-right">
                  <h4>{locale.searchRequisite[currentLanguage]}</h4>
                  <h5>{data.prerequisites || ""}</h5>
                </div>
              </div>
              <div className="item-container">
                <h4>{locale.searchAudience[currentLanguage]} : </h4>
                <h5>{data.audiencebackground || ""}</h5>
              </div>
              <div className="item-container">
                <h4>{locale.searchDescription[currentLanguage]} : </h4>
                <h5>{data.description || ""}</h5>
              </div>
              <div className="training-left-bottom">
                <a data-toggle="modal" data-target="#calenderModal">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  {locale.searchCallender[currentLanguage]}
                </a>
                <a data-toggle="modal" data-target="#contentModal">
                  <i className="fa fa-file" aria-hidden="true"></i>
                  {locale.searchViewContent[currentLanguage]}
                </a>
                <a target="_blank" href={data.link}>
                  <i className="fa fa-globe" aria-hidden="true"></i>
                  {locale.searchOfficalSite[currentLanguage]}
                </a>
                <AuthenticatedTemplate>
                  {events.length !== 0 &&
                    !events[0].isbooked &&
                    cartItems.findIndex(
                      (v: any) => v.event.skill == events[0].skill
                    ) == -1 &&
                    moment().diff(moment(events[0].startdate)) < 0 && (
                      <button
                        onClick={() => addItemCart()}
                        className={`${
                          cartItems.filter(
                            (item: any) => item.course.rowKey === data.rowKey
                          ).length > 0 && "addedToCart"
                        }`}
                      >
                        <i
                          className="fa fa-cart-plus fa-lg"
                          aria-hidden="true"
                        ></i>
                        {locale.searchAddCart[currentLanguage]}
                      </button>
                    )}
                  {events.filter((ets: any) => ets.isbooked === 0).length >
                    0 && (
                    <div className="nextTraining">
                      <h4>{locale.searchNextTraining[currentLanguage]}:</h4>
                      <h5>
                        {moment().diff(
                          moment(
                            events.filter((e: any) => e.isbooked === 0)[0]
                              .startdate
                          )
                        ) < 0
                          ? moment(
                              events.filter((e: any) => e.isbooked === 0)[0]
                                .startdate
                            ).format("MMM DD, YYYY")
                          : "TBA"}
                      </h5>
                    </div>
                  )}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  {events.length > 0 && (
                    <div className="nextTraining">
                      <h4>{locale.searchNextTraining[currentLanguage]}:</h4>
                      <h5>
                        {moment().diff(moment(events[0].startdate)) < 0
                          ? moment(events[0].startdate).format("MMM DD, YYYY")
                          : "TBA"}
                      </h5>
                    </div>
                  )}
                </UnauthenticatedTemplate>
              </div>
            </div>
            <div className="training-right">
              {isFetchingCalender ? (
                <ReactLoading
                  width={58}
                  height={58}
                  type="bars"
                  color="#333"
                  className="loading-bars"
                />
              ) : events.length === 0 ? (
                <div className="not-found">
                  <img src={noEvent} alt="NO CALENDAR" />
                  <h6>{locale.searchNoEvents[currentLanguage]}</h6>
                </div>
              ) : (
                events.map((event: any, index: number) => (
                  <div key={index} className="option">
                    <div className="option-content">
                      <div key={index} className="calendar-wrapper">
                        <h5>
                          {locale.trainingDetailCalender[currentLanguage]}
                        </h5>
                        <div className="time-wrapper">
                          <h4>{`${moment(event.startdate).format(
                            "D MMMM YYYY"
                          )}  ${moment(event.starttime, "HH:mm:ss").format(
                            "h:mm a"
                          )}`}</h4>
                          <h4>{`${moment(event.enddate).format(
                            "D MMMM YYYY"
                          )} ${moment(event.endtime, "HH:mm:ss").format(
                            "h:mm a"
                          )}`}</h4>
                        </div>
                      </div>
                      <div className="price-wrapper">
                        <h5>{locale.trainingDetailPrice[currentLanguage]}</h5>
                        <h4>{`£ ${event.price}/seat`}</h4>
                      </div>
                      <div className="timezone-wrapper">
                        <h5>
                          {locale.trainingDetailTimezone[currentLanguage]}
                        </h5>
                        <h4>{`${event.timezone}`}</h4>
                      </div>
                    </div>
                    {moment().diff(moment(event.startdate)) < 0 &&
                      (accounts.length === 0 ? (
                        <div className="btn-container">
                          <button
                            onClick={() =>
                              instance
                                .loginPopup(loginRequest)
                                .then(
                                  ({
                                    account: { name, username, localAccountId },
                                  }: any) => {
                                    dispatch(
                                      addUser({
                                        fullname: name,
                                        email: username,
                                        userId: localAccountId,
                                      })
                                    );
                                    toast.success("Signed in successfully!");
                                    saveUserInfo(name, localAccountId);
                                    gaEventsTrackerAccount("Log In", name);
                                  }
                                )
                            }
                          >
                            <i className="fa fa-user" aria-hidden="true"></i>
                            {locale.searchBookNow[currentLanguage]}
                          </button>
                          <button
                            onClick={() =>
                              instance
                                .loginPopup(loginRequest)
                                .then(
                                  ({
                                    account: { name, username, localAccountId },
                                  }: any) => {
                                    dispatch(
                                      addUser({
                                        fullname: name,
                                        email: username,
                                        userId: localAccountId,
                                      })
                                    );
                                    toast.success("Signed in successfully!");
                                    saveUserInfo(name, localAccountId);
                                    gaEventsTrackerAccount("Log In", name);
                                  }
                                )
                            }
                          >
                            <i className="fa fa-users" aria-hidden="true"></i>
                            {locale.searchBulkBook[currentLanguage]}
                          </button>
                        </div>
                      ) : event.isbooked && accounts.length > 0 ? (
                        <div className="btn-container">
                          <button
                            onClick={() =>
                              history.push({ pathname: "/mylearning" })
                            }
                          >
                            {locale.trainingDetailGotoTraining[currentLanguage]}
                          </button>
                        </div>
                      ) : checkInCart(index) ? (
                        <div className="btn-container">
                          <button disabled className="not-allowed">
                            <i
                              className="fa fa-shopping-cart fa-lg"
                              aria-hidden="true"
                            ></i>
                            {locale.searchAddedToCart[currentLanguage]}
                          </button>
                          {checkEventInCart(index) && (
                            <button disabled className="not-allowed">
                              <i
                                className="fa fa-shopping-cart fa-lg"
                                aria-hidden="true"
                              ></i>
                              {locale.searchAddedToCart[currentLanguage]}
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="btn-container">
                          <button
                            data-toggle="modal"
                            data-target="#confirmModal"
                            ref={confirmRef}
                            onClick={() => handleBookNow(index)}
                          >
                            <i className="fa fa-user" aria-hidden="true"></i>
                            {locale.searchBookNow[currentLanguage]}
                          </button>
                          <button
                            data-toggle="modal"
                            data-target="#bulkModal"
                            onClick={() => {
                              setBulkId(index);
                              setBulkBooking({
                                email: "",
                                seat: 1,
                                price: events[index].price,
                              });
                            }}
                          >
                            <i className="fa fa-users" aria-hidden="true"></i>
                            {locale.searchBulkBook[currentLanguage]}
                          </button>
                        </div>
                      ))}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="contentModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Course Outline</h4>
            </div>
            <div className="modal-body iframe">
              <iframe src={data.content || null}></iframe>
            </div>
          </div>
        </div>
      </div>
      <div id="calenderModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Events Schedule</h4>
            </div>
            <div className="modal-body calender-content">
              <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={[
                  ...events.map((event: any, index: number) => {
                    return (
                      moment().diff(moment(event.startdate)) < 0 && {
                        start: moment(event.startdate).toDate(),
                        end: moment(event.enddate).toDate(),
                        title: `Course Schedule - Event ${index + 1}`,
                      }
                    );
                  }),
                ]}
                style={{ width: "100%", height: "100%" }}
                views={[Views.MONTH]}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="bulkModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                ref={bulkClose}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
              <h4 className="modal-title">Bulk Booking</h4>
            </div>
            <div
              className={`modal-body bulk-booking ${
                accounts.length === 0 && "disable-bulk-booking"
              }`}
            >
              <div className="email-container">
                <div className="form-group-email">
                  <div className="label-container">
                    <label>Email</label>
                    {!isBulkEmailValid && (
                      <span className="error">
                        {bulkBooking.email === "" ? `Required` : `Invalid`}
                      </span>
                    )}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`user@gmail.com`}
                    value={bulkBooking.email}
                    onChange={({ target }) =>
                      handleInputChange(target.value, "email")
                    }
                  />
                </div>
                <div className="form-group-seat">
                  <div className="label-container">
                    <label>Number of seats</label>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    min={1}
                    value={bulkBooking.seat}
                    onChange={({ target }) =>
                      handleInputChange(target.value, "seat")
                    }
                  />
                </div>
                <div className="price-group">
                  <label>Total Price</label>
                  <div className="price-control">{`$ ${numberWithComma(
                    String(bulkBooking.price)
                  )}`}</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {accounts.length === 0 ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    instance
                      .loginPopup()
                      .then(
                        ({
                          account: { name, username, localAccountId },
                        }: any) => {
                          dispatch(
                            addUser({
                              fullname: name,
                              email: username,
                              userId: localAccountId,
                            })
                          );
                          toast("Signed in successfully!");
                          saveUserInfo(name, localAccountId);
                        }
                      )
                  }
                >
                  Sign In
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    bulkClose.current.click();
                    confirmRef.current.click();
                    addItemPayment(bulkId, true);
                  }}
                  disabled={!isBulkEmailValid}
                >
                  <i
                    className="fa fa-check-circle fa-lg"
                    aria-hidden="true"
                  ></i>
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <Payment sum={price} handlePaymentResult={handlePaymentResult} />
      </Elements>
    </Fragment>
  );
};

export default TrainingDetails;
