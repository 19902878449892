import React from "react";

const PathNotFound = () => {
  return (
    <div className="path-notFound-container">
      <h2>Page Not Found</h2>
      <i className="fa fa-frown-o fa-5x" aria-hidden="true"></i>
      <a href="/">Back to Home</a>
    </div>
  );
};

export default PathNotFound;
