import React, { useState } from "react";
import OverView from "./OverView";
import RequestForm from "./RequestForm";
import GoogleMap from "./GoogleMap";
import Statistics from "./Statistics";
import Clients from "./Clients";
import Service from "./Service";
import Quote from "./Quote";
import { SocialIcon } from "react-social-icons";

import { locale } from "../../utils/locale";
import {
  INIT_FEEDBACK_DETAILS,
  REPLY_TRAINING_ADDRESS,
} from "../../config/constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { validate_feedback_details } from "../../utils/validate";
import { sendMail } from "../../config/api";
import { useSelector } from "react-redux";

const Home = () => {
  const currentLang = useSelector((state: any) => state.currentLanguage);
  const [isSending, setIsSending] = useState(false);

  const formik = useFormik({
    initialValues: INIT_FEEDBACK_DETAILS,
    onSubmit: (values) => {
      const msg = {
        subject: `${values.fullname} left a feedback on consultingapt.com`,
        to: [
          {
            email: "shantanu@consultingapt.in",
            name: "Shantanu Pandey",
          },
          {
            email: "guneet@consulting.in",
            name: "Guneet Singh",
          },
        ],
        from: {
          email: REPLY_TRAINING_ADDRESS,
          name: "APT Consulting Ltd",
        },
        replyTo: {
          email: values.email,
          name: values.fullname,
        },
        htmlcontent: values.message + values.email,
      };
      sendFeedback(msg);
    },
    validate: (data) => validate_feedback_details(data),
  });

  const sendFeedback = async (data: any) => {
    setIsSending(true);
    await sendMail(data)
      .then(({ data: { errorMsg } }: any) => {
        if (errorMsg === "success") {
          formik.values.email = "";
          formik.values.fullname = "";
          formik.values.message = "";
          toast.success("We will get in touch with you shortly!", {
            position: "bottom-left",
          });
        } else {
          toast.warning("Error, Please try it again!", {
            position: "bottom-left",
          });
        }
        setIsSending(false);
      })
      .catch((err) => {
        toast.error("Error, Please try it again!", {
          position: "bottom-left",
        });
        setIsSending(false);
      });
  };

  return (
    <div className="home-wrapper">
      <OverView />
      <div className="main">
        <Quote />
        <Service />
        <Statistics />
        <Clients />
        <RequestForm />
        <GoogleMap />
      </div>
      <div className="scroll-up">
        <a href="#totop">
          <i className="fa fa-angle-double-up"></i>
        </a>
      </div>
      <div className="social-links">
        <SocialIcon
          network="facebook"
          target="_blank"
          style={{ width: 30, height: 30 }}
          url="https://facebook.com/APT-Consulting-106333858701428"
        />
        <SocialIcon
          network="twitter"
          target="_blank"
          style={{ width: 30, height: 30 }}
          url="https://twitter.com/apt_consulting"
        />
        <SocialIcon
          network="linkedin"
          target="_blank"
          style={{ width: 30, height: 30 }}
          url="https://linkedin.com/company/aptconsulting"
        />
        <SocialIcon
          network="instagram"
          target="_blank"
          style={{ width: 30, height: 30 }}
          url="https://instagram.com"
        />
      </div>
      <a
        className="feedback-btn"
        data-toggle="modal"
        data-target="#feedbackModal"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
        {locale.feedbackButtonFloat[currentLang]}
      </a>
      <div id="feedbackModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h6> {locale.feedbackTitle[currentLang]}</h6>
            </div>
            <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <label className="sr-only">
                    {locale.feedbackInput1[currentLang]}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="fullname"
                    value={formik.values.fullname}
                    placeholder={locale.feedbackInputPlaceholder1[currentLang]}
                    onChange={formik.handleChange}
                    disabled={isSending}
                  />
                  {formik.errors.fullname && (
                    <p className="error">{formik.errors.fullname}</p>
                  )}
                </div>
                <div className="form-group">
                  <label className="sr-only">
                    {locale.feedbackInput2[currentLang]}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    value={formik.values.email}
                    placeholder={locale.feedbackInputPlaceholder2[currentLang]}
                    onChange={formik.handleChange}
                    disabled={isSending}
                  />
                  {formik.errors.email && (
                    <p className="error">{formik.errors.email}</p>
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows={7}
                    name="message"
                    value={formik.values.message}
                    placeholder={locale.feedbackInputPlaceholder3[currentLang]}
                    onChange={formik.handleChange}
                    style={{ resize: "none" }}
                    disabled={isSending}
                  ></textarea>
                  {formik.errors.message && (
                    <p className="error">{formik.errors.message}</p>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-block btn-round btn-d"
                    disabled={isSending}
                  >
                    {isSending
                      ? locale.feedbackButtonSend[currentLang]
                      : locale.feedbackButton[currentLang]}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
