import React, { Fragment } from "react";
import { IToggleSwitch } from "../../utils/types";

const ToggleSwitch = ({
  handleToggleSwitch,
  item = "",
  value = false,
  type = "",
  subCat = "",
}: IToggleSwitch) => {
  return (
    <Fragment>
      <label className="item">
        <input
          type="checkbox"
          checked={value}
          onChange={() => handleToggleSwitch(item === "" ? type : item)}
        />
        <span>{item.replace("!", " ")}</span>
      </label>
    </Fragment>
  );
};

export default ToggleSwitch;
