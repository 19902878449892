import React, { Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  addUser,
  removeAll,
  removeUser,
  setLanguage,
} from "../../store/actions";
import { locale } from "../../utils/locale";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest, forgotPasswordRequest } from "../../config/authConfig";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { saveUserInfo } from "../../config/api";
import { Emojione } from "react-emoji-render";
import { useHistory, useLocation } from "react-router-dom";
import { FaUserCircle, FaSignInAlt } from "react-icons/fa";
import { FLAGS } from "../../config/constants";
import { useGAEventsTracker } from "../../hooks";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { instance } = useMsal();
  const { cartItems, currentLanguage } = useSelector((state: any) => state);
  const currentUser = useSelector((state: any) => state.currentUser);
  const gaEventsTracker = useGAEventsTracker("Account");
  const refLink: any = useRef();

  return (
    <nav
      className="navbar navbar-custom navbar-fixed-top navbar-transparent"
      role="navigation"
    >
      <div className="navbar-header">
        <div className="navbar-brand">
          {/* {(pathname === "/mylearning" || pathname === "/dashboard") && (
            <i
              className="fa fa-bars"
              aria-hidden="true"
              onClick={toggleDrawer}
            ></i>
          )} */}
          <img onClick={() => refLink.current.click()} src={logo} alt="APT" />
          <Link ref={refLink} style={{ display: "none" }} to="/"></Link>
        </div>
        <div className="toggle-container">
          <div className="account-mobile-wrapper">
            <AuthenticatedTemplate>
              <li className="dropdown cart-icon">
                <Link to="/cart">
                  <i
                    className="fa fa-shopping-cart fa-lg cart-icon"
                    aria-hidden="true"
                  >
                    {cartItems.length !== 0 && <span>{cartItems.length}</span>}
                  </i>
                </Link>
              </li>
            </AuthenticatedTemplate>
            <li className="dropdown">
              <a
                className="account-mobile-anchor dropdown-toggle"
                data-toggle="dropdown"
              >
                <i
                  className="fa fa-user-circle fa-lg"
                  data-toggle="dropdown"
                ></i>
              </a>
              <ul className="dropdown-menu">
                <UnauthenticatedTemplate>
                  <li
                    onClick={() => {
                      instance
                        .loginPopup(loginRequest)
                        .then(
                          ({
                            account: { name, username, localAccountId },
                          }: any) => {
                            dispatch(
                              addUser({
                                fullname: name,
                                email: username,
                                userId: localAccountId,
                              })
                            );
                            toast.success("Signed in successfully!");
                            saveUserInfo(name, localAccountId);
                            gaEventsTracker("Log In", name);
                          }
                        );
                    }}
                  >
                    <a>{locale.signIn[currentLanguage]}</a>
                  </li>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <li>
                    <Link to="/dashboard">
                      {locale.signIn2[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        instance.logoutPopup().then(() => {
                          dispatch(removeAll());
                          dispatch(removeUser());
                          gaEventsTracker(
                            "Log out",
                            currentUser && currentUser.fullname
                          );
                        });
                      }}
                    >
                      {locale.signIn3[currentLanguage]}
                    </a>
                  </li>
                </AuthenticatedTemplate>
              </ul>
            </li>
            <li className="dropdown langs">
              <a
                className="dropdown-toggle langs-anchor"
                data-toggle="dropdown"
              >
                <Emojione
                  text={`${
                    currentLanguage === "gb"
                      ? FLAGS.gb.code
                      : currentLanguage === "us"
                      ? FLAGS.us.code
                      : FLAGS.fr.code
                  }`}
                />{" "}
                {currentLanguage === "gb"
                  ? FLAGS.gb.text
                  : currentLanguage === "us"
                  ? FLAGS.us.text
                  : FLAGS.fr.text}
              </a>
              <ul className="dropdown-menu">
                <li
                  className={`${currentLanguage === "gb" && "selected"}`}
                  onClick={() => dispatch(setLanguage("gb"))}
                >
                  <Emojione text="🇬🇧" /> En-GB
                </li>
                <li
                  className={`${currentLanguage === "us" && "selected"}`}
                  onClick={() => dispatch(setLanguage("us"))}
                >
                  <Emojione text="🇺🇸" /> En-US
                </li>
                <li
                  className={`${currentLanguage === "fr" && "selected"}`}
                  onClick={() => dispatch(setLanguage("fr"))}
                >
                  <Emojione text="🇫🇷" /> Fr-Ca
                </li>
              </ul>
            </li>
          </div>
          <button
            className="navbar-toggle"
            type="button"
            data-toggle="collapse"
            data-target="#custom-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
      </div>
      <div className="collapse navbar-collapse" id="custom-collapse">
        <ul className="nav navbar-nav navbar-right">
          {pathname === "/dashboard" || pathname === "/mylearning" ? (
            <Fragment>
              <li
                className={`dropdown ${
                  pathname === "/mylearning" && "activeTab"
                }`}
              >
                <Link to="/mylearning">
                  {locale.myLearning[currentLanguage]}
                </Link>
              </li>
              <li className="dropdown input-wrapper">
                <a>
                  <input type="text" placeholder="search" />
                </a>
              </li>
              {/* <li className="dropdown">
                <a>
                  <i className="fa fa-question-circle fa-2xl"></i>
                </a>
              </li> */}
            </Fragment>
          ) : (
            <Fragment>
              <li className="dropdown">
                <a className="dropdown-toggle" href="#" data-toggle="dropdown">
                  {locale.verifyCertificate[currentLanguage]}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="#">
                      {locale.verifyCertificateSub1[currentLanguage]}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {locale.verifyCertificateSub2[currentLanguage]}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">
                  {locale.learningPaths[currentLanguage]}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to={`/search?word=Accounting & Finance`}>
                      {locale.learningPaths1[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=Analytics">
                      {locale.learningPaths2[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=Artificial Intelligence">
                      {locale.learningPaths3[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=Cloud Developer">
                      {locale.learningPaths4[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=cloud devops">
                      {locale.learningPaths5[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=data engineering">
                      {locale.learningPaths6[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=data science">
                      {locale.learningPaths7[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/search?word=Manufacturing & Supply Chain`}>
                      {locale.learningPaths8[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=marketing">
                      {locale.learningPaths9[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=operations">
                      {locale.learningPaths10[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=web developer">
                      {locale.learningPaths11[currentLanguage]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=security">
                      {locale.learningPaths12[currentLanguage]}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">
                  {locale.byProducts[currentLanguage]}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/search?word=APT">APT</Link>
                  </li>
                  <li>
                    <Link to="/search?word=GoDaddy">GoDaddy</Link>
                  </li>
                  <li>
                    <Link to="/search?word=Mircosoft Azure">
                      Mircosoft Azure
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=Microsoft Dynamics">
                      Microsoft Dynamics
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?word=Microsoft Office">
                      Microsoft Office
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">
                  {locale.aboutAPT[currentLanguage]}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="#callback">{locale.aboutAPT1[currentLanguage]}</a>
                  </li>
                  {/* <li>
                    <a href="#contactus">{locale.aboutAPT2[currentLanguage]}</a>
                  </li> */}
                  <li>
                    <a href="#aboutus">{locale.aboutAPT3[currentLanguage]}</a>
                  </li>
                </ul>
              </li>
              <UnauthenticatedTemplate>
                <li className="dropdown">
                  <a
                    onClick={() => {
                      instance
                        .loginPopup(loginRequest)
                        .then(
                          ({
                            account: { name, username, localAccountId },
                          }: any) => {
                            dispatch(
                              addUser({
                                fullname: name,
                                email: username,
                                userId: localAccountId,
                              })
                            );
                            toast.success("Signed in successfully!");
                            saveUserInfo(name, localAccountId);
                            gaEventsTracker("Log In", name);
                          }
                        );
                    }}
                  >
                    {locale.creatAccount[currentLanguage]}
                  </a>
                </li>
              </UnauthenticatedTemplate>
            </Fragment>
          )}
          <div className="account-wrapper">
            <AuthenticatedTemplate>
              <li>
                <Link to="/cart">
                  <i
                    className="fa fa-shopping-cart fa-lg cart-icon"
                    aria-hidden="true"
                  >
                    {cartItems.length !== 0 && <span>{cartItems.length}</span>}
                  </i>
                </Link>
              </li>
            </AuthenticatedTemplate>
            <li className="dropdown">
              <a
                className="dropdown-toggle account-anchor"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle fa-lg"></i>
              </a>
              <ul className="dropdown-menu account-menu">
                <UnauthenticatedTemplate>
                  <li
                    className="unauth"
                    onClick={() => {
                      instance
                        .loginPopup(loginRequest)
                        .then(
                          ({
                            account: { name, username, localAccountId },
                          }: any) => {
                            dispatch(
                              addUser({
                                fullname: name,
                                email: username,
                                userId: localAccountId,
                              })
                            );
                            toast.success("Signed in successfully!", {
                              position: "bottom-right",
                              pauseOnHover: false,
                            });
                            saveUserInfo(name, localAccountId);
                          }
                        )
                        .catch((err) => {
                          if (
                            err.errorMessage != null &&
                            err.errorMessage.includes("AADB2C90118")
                          ) {
                            instance
                              .loginRedirect(forgotPasswordRequest)
                              .then(() => {
                                toast.success(
                                  "You password has been reset successfully!",
                                  { position: "bottom-center" }
                                );
                              });
                          }
                        });
                    }}
                  >
                    <FaSignInAlt size={20} />
                    <a>{locale.signIn[currentLanguage]}</a>
                  </li>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <li className="account-dropdown">
                    <FaUserCircle size={60} />

                    <div className="user-info">
                      <p>{currentUser && currentUser.fullname}</p>
                      <span>{currentUser && currentUser.email}</span>
                      <button onClick={() => history.push("/dashboard")}>
                        {locale.dashboard[currentLanguage]}
                      </button>
                    </div>

                    <hr />

                    <button
                      onClick={() => {
                        instance.logoutPopup().then(() => {
                          dispatch(removeAll());
                          dispatch(removeUser());
                          if (
                            pathname === "/mylearning" ||
                            pathname === "/dashboard"
                          ) {
                            window.location.reload();
                          }
                        });
                      }}
                      className="signout"
                    >
                      Sign Out
                    </button>
                  </li>
                </AuthenticatedTemplate>
              </ul>
            </li>
            <li className="dropdown">
              <a
                className="dropdown-toggle langs-anchor"
                data-toggle="dropdown"
              >
                <Emojione
                  text={`${
                    currentLanguage === "gb"
                      ? FLAGS.gb.code
                      : currentLanguage === "us"
                      ? FLAGS.us.code
                      : FLAGS.fr.code
                  }`}
                />{" "}
                {currentLanguage === "gb"
                  ? FLAGS.gb.text
                  : currentLanguage === "us"
                  ? FLAGS.us.text
                  : FLAGS.fr.text}
              </a>
              <ul className="dropdown-menu langs-menu">
                <li
                  className={`${currentLanguage === "gb" && "selected"}`}
                  onClick={() => dispatch(setLanguage("gb"))}
                >
                  <a>
                    <Emojione text="🇬🇧" /> En-GB
                  </a>
                </li>
                <li
                  className={`${currentLanguage === "us" && "selected"}`}
                  onClick={() => dispatch(setLanguage("us"))}
                >
                  <a>
                    <Emojione text="🇺🇸" /> En-US
                  </a>
                </li>
                <li
                  className={`${currentLanguage === "fr" && "selected"}`}
                  onClick={() => dispatch(setLanguage("fr"))}
                >
                  <a>
                    <Emojione text="🇫🇷" /> Fr-Ca
                  </a>
                </li>
              </ul>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
