import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  EN_SUBJECTS,
  FR_SUBJECTS,
  INIT_REQUEST_CALLBACK,
  REPLY_TRAINING_ADDRESS,
} from "../../config/constants";
import { locale } from "../../utils/locale";
import request_bg from "../../assets/images/request.png";
import { useFormik } from "formik";
import { validate_request_callback } from "../../utils/validate";
import { sendMail } from "../../config/api";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";

const RequestForm = () => {
  const currentLang = useSelector((state: any) => state.currentLanguage);
  const [phonePrefix, setPhonePrefix] = useState("");
  const [country, setCountry] = useState<any>("");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const recaptchaRef: any = useRef();
  const [recaptcha, setRecaptcha] = useState("");
  const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then(({ data, status }) => {
        if (status === 200 && Object.keys(data).length > 0) {
          setCountry(data.country_code);
          setPhonePrefix(data.country_calling_code);
        } else {
          setCountry("IN");
          setPhonePrefix("+91");
        }
      })
      .catch((_) => {
        setCountry("IN");
        setPhonePrefix("+91");
      });
  }, []);

  const formik = useFormik({
    initialValues: INIT_REQUEST_CALLBACK,
    onSubmit: (values) => {
      const msg = {
        subject: `${values.name} request a call back`,
        to: [
          {
            email: "shantanu@consultingapt.in",
            name: "Shantanu Pandey",
          },
        ],
        replyto: {
          email: "",
          name: "",
        },
        from: {
          email: REPLY_TRAINING_ADDRESS,
          name: "APT Consulting Ltd",
        },
        htmlcontent: `Request subject: <b>${values.subject}</b> <br/> Phone: <b>+${phonePrefix}-${values.phone}</b>`,
      };
      if (recaptcha !== "") {
        sendRequest(msg);
      } else {
        toast.warning("Please complete google re-captcha", {
          position: "bottom-center",
        });
      }
    },
    validate: (data) => validate_request_callback(data),
  });

  const sendRequest = async (data: any) => {
    setIsSubmiting(true);
    await sendMail(data)
      .then(({ data: { errorMsg } }: any) => {
        setIsSubmiting(false);
        if (errorMsg === "success") {
          toast.success("Thank you! We will get in touch soon!", {
            position: "bottom-center",
            draggable: false,
            pauseOnFocusLoss: false,
          });
          formik.values.name = "";
          formik.values.phone = "";
          formik.values.subject = "subject";
        } else {
          toast.warning(
            "It seems, we are unable to process your request. Try it again",
            {
              position: "bottom-center",
              draggable: false,
              pauseOnFocusLoss: false,
            }
          );
        }
      })
      .catch(() => {
        setIsSubmiting(false);
        toast.error("Error!, Unable to send your request. Contact the admin", {
          position: "bottom-center",
          draggable: false,
          pauseOnFocusLoss: false,
        });
      });
  };

  return (
    <section
      id="callback"
      className="module bg-dark-60 request"
      style={{
        backgroundImage: `url(${request_bg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <h3 className="font-alt">{locale.requestTitle[currentLang]}</h3>
            <p>{ReactHtmlParser(locale.requestDetail[currentLang])}</p>
          </div>
          <form onSubmit={formik.handleSubmit} className="col-sm-8">
            <div className="form-group col-sm-6 col-xs-12">
              {formik.errors.name && (
                <small className="error">{formik.errors.name}</small>
              )}
              <input
                className="form-control input-lg"
                type="text"
                name="name"
                placeholder={locale.requestPlaceholderName[currentLang]}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="form-group col-sm-6 col-xs-12">
              {formik.errors.subject && (
                <small className="error">{formik.errors.subject}</small>
              )}
              <select
                className="form-control input-lg"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
              >
                {(currentLang === "us" || currentLang === "gb"
                  ? EN_SUBJECTS
                  : FR_SUBJECTS
                ).map(
                  (item: { name: string; value: string }, index: number) => (
                    <Fragment key={index}>
                      {index === 0 ? (
                        <option value={item.value} hidden>
                          {item.name}
                        </option>
                      ) : (
                        <option value={item.value}> {item.name}</option>
                      )}
                    </Fragment>
                  )
                )}
              </select>
            </div>
            <div className="form-group col-sm-6 col-xs-12">
              {formik.errors.phone && (
                <small className="error">{formik.errors.phone}</small>
              )}
              <PhoneInput
                placeholder={locale.requestPlaceholderPhone[currentLang]}
                defaultCountry={country}
                value={phonePrefix}
                onChange={(phone: any) => {
                  setPhonePrefix(phone);
                  formik.setFieldValue("phone", phone);
                }}
              />
            </div>
            <div className="form-group col-sm-6 col-xs-12">
              {formik.errors.consent && (
                <small className="error">{formik.errors.consent}</small>
              )}
              <label>
                <input
                  type="checkbox"
                  name="consent"
                  checked={formik.values.consent}
                  onChange={formik.handleChange}
                />
                I consent to being called back by APT Consulting's
                representative.
              </label>
              {/*<ReCAPTCHA
                ref={recaptchaRef}
                sitekey={TEST_SITE_KEY}
                size="normal"
                onChange={(value: any) => setRecaptcha(value)}
              />*/}
              {isSubmiting ? (
                <button
                  disabled
                  className="btn btn-border-w btn-circle btn-block"
                >
                  {locale.requestSendingButton[currentLang]}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-border-w btn-circle btn-block btn-submit"
                >
                  <i className="fa fa-paper-plane-o"></i>{" "}
                  {locale.requestButton[currentLang]}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default RequestForm;
