import axios from "axios";
import { TIMEOUT } from "./constants";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = TIMEOUT;

export const saveUserInfo = (name: string, id: string) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/SaveUser",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        name: name,
        userid: id,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const getTrainings = (
  word: string,
  filter_oem?: string,
  filter_role?: string
) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/GetContent",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        search: word,
        oem: filter_oem,
        roles: filter_role,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const getTrainingCalender = (skill: string, id: number) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/GetTrainingCalendar",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        skill: skill,
        userid: id,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const getPaymentIntent = (amount: string, currency: string) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/PaymentIntent",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        secret_key: process.env.REACT_APP_SECRET_KEY,
        amount: amount,
        currency: currency,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const saveTrainingToBook = (data: any) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/TrainingOrder",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        ...data,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const sendMail = (data: any) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/SendGridEmail",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        sendgrid_api_key: process.env.REACT_APP_SENDGRID_API_KEY,
        ...data,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const getMyLearnings = (id: string) =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/MyLearning",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
        userid: id,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const getRoles = () =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/GetRoles",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );

export const getOEM = () =>
  new Promise((resolve, reject) =>
    axios({
      method: "post",
      url: "Training/GetOEM",
      data: {
        apikey: process.env.REACT_APP_ENDPOINT_API_KEY,
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );
