import React, { useEffect, useState } from "react";
import Filters from "./Filters";
import TrainingCard from "./TrainingCard";
import { getTrainings } from "../../config/api";
import { ITraining } from "../../utils/types";
import ReactLoading from "react-loading";
import { IoSearchCircleOutline } from "react-icons/io5";
import { Pagination } from "@material-ui/lab";
import TrainingDetails from "./TrainingDetails";
import { useGAEventsTracker } from "../../hooks";
import { SocialIcon } from "react-social-icons";

const SearchTrainings = ({ location }: any) => {
  const [searchWord, setSearchWord] = useState(
    new URLSearchParams(location.search).get("word") || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ITraining[]>([]);
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [currentData, setCurrentData] = useState<ITraining>();
  const [currentOEM, setCurrentOEM] = useState<string[]>([]);
  const [currentRole, setCurrentRole] = useState<string[]>([]);
  const gaEventsTracker = useGAEventsTracker("Search");

  useEffect(() => {
    fetchEvents();
    gaEventsTracker(
      searchWord,
      currentOEM.toString() || currentRole.toString()
    );
  }, [searchWord, currentOEM, currentRole]);

  const fetchEvents = async () => {
    setIsLoading(true);
    await getTrainings(
      searchWord,
      currentOEM.toString(),
      currentRole.toString()
    )
      .then(({ status, data }: any) => {
        if (status === 200 && data.errorMsg === "success") {
          setData(data.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  const handleDetails = (current: any) => {
    setShowDetail(true);
    setCurrentData(current);
  };

  return (
    <div className="search-wrapper">
      {!showDetail ? (
        <div className="search-main">
          <Filters
            currentOEM={currentOEM}
            currentRole={currentRole}
            setCurrentOEM={setCurrentOEM}
            setCurrentRole={setCurrentRole}
          />
          <div className="right-content">
            <div className="company-info">
              <div className="info-left">
                <a href="mailto:info@consultingapt.com">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  {` info@consultingapt.com`}
                </a>
                <div className="phone">
                  <i className="fa fa-phone" aria-hidden="true"></i> 8883836659
                 
                </div>
              </div>
              <div className="info-right">
                <SocialIcon
                  network="facebook"
                  target="_blank"
                  style={{ width: 25, height: 25 }}
                  url="https://facebook.com/APT-Consulting-106333858701428"
                />
                <SocialIcon
                  network="twitter"
                  target="_blank"
                  style={{ width: 25, height: 25 }}
                  url="https://twitter.com/apt_consulting"
                />
                <SocialIcon
                  network="linkedin"
                  target="_blank"
                  style={{ width: 25, height: 25 }}
                  url="https://linkedin.com/company/aptconsulting"
                />
                <SocialIcon
                  network="instagram"
                  target="_blank"
                  style={{ width: 25, height: 25 }}
                  url="https://instagram.com"
                />
              </div>
            </div>
            <div className="right-top">
              <div className="search-page-input">
                <input
                  type="text"
                  value={searchWord}
                  onChange={(event) => setSearchWord(event.target.value)}
                  placeholder="Data Science, AI, Finance, Marketing, Microsoft courses, SQL, Excel ..."
                />
                {searchWord ? (
                  <i
                    onClick={() => setSearchWord("")}
                    id="delete"
                    className="fa fa-times fa-lg"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i className="fa fa-search fa-lg" aria-hidden="true"></i>
                )}
              </div>
            </div>
            <div className="right-bottom">
              {hasError ? (
                <div className="nothing-found">
                  <IoSearchCircleOutline className="error" size={75} />
                  <h5>Error, Unable to fetch!</h5>
                </div>
              ) : isLoading ? (
                <ReactLoading
                  type="spinningBubbles"
                  color="#3842DC"
                  className="loading"
                />
              ) : data.length === 0 ? (
                <div className="nothing-found">
                  <IoSearchCircleOutline className="empty" size={75} />
                  <h4>Nothing Found!</h4>
                </div>
              ) : (
                <div className="pagination-wrapper">
                  <div className="data-page-container">
                    {data
                      .slice((page - 1) * 9, (page - 1) * 9 + 9)
                      .map((item: ITraining, index) => (
                        <TrainingCard
                          key={index}
                          idx={index}
                          data={item}
                          handleDetails={handleDetails}
                        />
                      ))}
                  </div>
                  <Pagination
                    className="pagination"
                    count={Math.ceil(data.length / 5)}
                    onChange={(_, value) => {
                      setPage(value);
                      window.scroll(0, 0);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <TrainingDetails
          currentData={currentData}
          setShowDetail={setShowDetail}
        />
      )}
    </div>
  );
};

export default SearchTrainings;
