import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { locale } from "../../utils/locale";
import { getMyLearnings } from "../../config/api";
import ReacLoading from "react-loading";
import moment from "moment";
import { toast } from "react-toastify";

import tile from "../../assets/images/tile.jpg";
import bg from "../../assets/images/dashboard.jpeg";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useSelector((state: any) => state);
  const [upcomingTraining, setUpcomingTraining] = useState<any>([]);
  const [pastTraining, setPastTraining] = useState<any>([]);
  const [activeTraining, setActiveTraining] = useState<any>([]);
  const [totalTraining, setTotalTrainig] = useState<any>([]);

  useEffect(() => {
    window.scroll(0, 0);
    fetchMyLearnings();
  }, []);

  const fetchMyLearnings = async () => {
    setIsLoading(true);
    await getMyLearnings(currentUser.userId)
      .then(({ data }: any) => {
        console.log(data);
        setIsLoading(false);
        if (data.errorMsg === "success") {
          const upcomings = data.data.filter(
            (item: any) => item.type === "Upcoming"
          );
          const insession = data.data.filter(
            (item: any) => item.type === "Insession"
          );
          const completed = data.data.filter(
            (item: any) => item.type === "Past"
          );
          setUpcomingTraining([...upcomings]);
          setActiveTraining([...insession]);
          setPastTraining([...completed]);
          setTotalTrainig([...upcomings, ...insession, ...completed]);
        }
      })
      .catch((e) => {
        toast.error(e);
        setIsLoading(false);
      });
  };

  const { currentLanguage } = useSelector((state: any) => state);
  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-top">
        <div className="top-left">
          <h4>{locale.dashboardTitle[currentLanguage]}</h4>
          <p>{locale.dashboardDescription[currentLanguage]}</p>
        </div>
        <img src={bg} />
      </div>
      {/* <div className="dashboard-bottom">
        <div className="tile-wrapper">
          <h5>{locale.dashboardInprogress[currentLanguage]}</h5>
          <div className="tile-content">
            <img src={tile} />
            <div className="tile-detail">
              <div className="tile-main">
                {locale.dashboardSchedule[currentLanguage]}
              </div>
              <div className="tile-name">
                Google Cloud Fundamentals for AWS Professionals{" "}
              </div>
              <div className="tile-date">Jun 13, 2022 9:30 AM IST</div>
              <div className="tile-country">India</div>
            </div>
          </div>
        </div>
        <button onClick={() => history.push("/mylearning")}>
          {locale.dashboardViewCourse[currentLanguage]}
        </button>
      </div> */}
      {isLoading ? (
        <ReacLoading type="bubbles" color="#3842DC" />
      ) : (
        <div className="dashboard-bottom">
          {activeTraining.length > 0 && (
            <div className="tile-wrapper">
              <div className="title">
                <h2>{activeTraining.length}</h2>
                <h5>{locale.dashboardInprogress[currentLanguage]}</h5>
              </div>
              <div className="tile-content">
                <img src={tile} />
                <div className="tile-detail">
                  <div className="tile-main">
                    {locale.dashboardSchedule[currentLanguage]}
                  </div>
                  <div className="tile-name">{activeTraining[0].skill}</div>
                  <div className="tile-date">
                    {moment(activeTraining[0].startDate).format(
                      "MMM DD, YYYY hh:mm A"
                    )}
                  </div>
                  <div className="tile-country">
                    {" "}
                    {activeTraining[0].timeZone}
                  </div>
                </div>
              </div>
            </div>
          )}
          {totalTraining.length > 0 && (
            <div className="tile-wrapper">
              <div className="title">
                <h2>{totalTraining.length}</h2>
                <h5>{locale.dashboardTotal[currentLanguage]}</h5>
              </div>
              <div className="tile-content">
                <img src={tile} />
                <div className="tile-detail">
                  <div className="tile-main">
                    {locale.dashboardSchedule[currentLanguage]}
                  </div>
                  <div className="tile-name">{totalTraining[0].skill}</div>
                  <div className="tile-date">
                    {moment(totalTraining[0].startDate).format(
                      "MMM DD, YYYY hh:mm A"
                    )}
                  </div>
                  <div className="tile-country">
                    {" "}
                    {totalTraining[0].timeZone}
                  </div>
                </div>
              </div>
            </div>
          )}
          {pastTraining.length > 0 && (
            <div className="tile-wrapper">
              <div className="title">
                <h2>{pastTraining.length}</h2>
                <h5>{locale.dashboardCompleted[currentLanguage]}</h5>
              </div>
              <div className="tile-content">
                <img src={tile} />
                <div className="tile-detail">
                  <div className="tile-main">
                    {locale.dashboardSchedule[currentLanguage]}
                  </div>
                  <div className="tile-name">
                    Google Cloud Fundamentals for AWS Professionals{" "}
                  </div>
                  <div className="tile-date">Jun 13, 2022 9:30 AM IST</div>
                  <div className="tile-country">India</div>
                </div>
              </div>
            </div>
          )}
          {upcomingTraining.length > 0 && (
            <div className="tile-wrapper">
              <div className="title">
                <h2>{upcomingTraining.length}</h2>
                <h5>{locale.dashboardUpcoming[currentLanguage]}</h5>
              </div>
              <div className="tile-content">
                <img src={tile} />
                <div className="tile-detail">
                  <div className="tile-main">
                    {locale.dashboardSchedule[currentLanguage]}
                  </div>
                  <div className="tile-name">{upcomingTraining[0].skill}</div>
                  <div className="tile-date">
                    {moment(upcomingTraining[0].startDate).format(
                      "MMM DD, YYYY hh:mm A"
                    )}
                  </div>
                  <div className="tile-country">
                    {upcomingTraining[0].timeZone}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="tile-wrapper">
            <h5>{locale.dashboardTranscript[currentLanguage]}</h5>
            <div className="tile-content">
              <img src={tile} />
              <div className="tile-detail">
                <div className="tile-main">
                  {locale.dashboardSchedule[currentLanguage]}
                </div>
                <div className="tile-name">
                  Google Cloud Fundamentals for AWS Professionals{" "}
                </div>
                <div className="tile-date">Jun 13, 2022 9:30 AM IST</div>
                <div className="tile-country">India</div>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
