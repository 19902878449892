import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { currentLanguage } from "./reducers/language";
import { cartItems } from "./reducers/cart";
import { user } from "./reducers/auth";
import { payItems } from "./reducers/pay";
import { IAppState } from "../utils/types";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
};

const allReducers: any = combineReducers<IAppState>({
  currentLanguage,
  cartItems,
  currentUser: user,
  payItems,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

export const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
