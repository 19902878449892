import {
  CHANGE_LANGUAGE,
  ADD_TO_CART,
  DELETE_FROM_CART,
  DELETE_ALL_CART,
  ADD_USER,
  REMOVE_USER,
  ADD_TO_PAY,
  DELETE_FROM_PAY,
  DELETE_ALL_PAY,
} from "../../config/constants";

/******************  LANGUAGE ACTIONS   **********************/

export const setLanguage = (lang: string) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: lang,
  };
};

/******************  END LANGUAGE ACTIONS   **********************/

/******************  CART ACTIONS   **********************/

export const addToCart = (data: any) => {
  return {
    type: ADD_TO_CART,
    payload: data,
  };
};

export const removeFromCart = (id: any) => {
  return {
    type: DELETE_FROM_CART,
    payload: id,
  };
};

export const removeAll = () => {
  return {
    type: DELETE_ALL_CART,
  };
};

/******************  END CART ACTIONS   **********************/

/******************  PAY ACTIONS   **********************/

export const addToPAY = (data: any) => {
  return {
    type: ADD_TO_PAY,
    payload: data,
  };
};

export const removeAllPay = () => {
  return {
    type: DELETE_ALL_PAY,
  };
};

/******************  END PAY ACTIONS   **********************/

/******************  USER ACTIONS   **********************/
export const addUser = (data: any) => {
  return {
    type: ADD_USER,
    payload: data,
  };
};

export const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};
/******************  END USER ACTIONS   **********************/
