import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMyLearnings } from "../../config/api";
import { Tabs, Tab } from "react-mdl";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { locale } from "../../utils/locale";

const MyLearning = () => {
  const [word, setWord] = useState("");
  const [data, setData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { currentUser, currentLanguage } = useSelector((state: any) => state);
  const history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
    fetchMyLearnings();
  }, [activeTab, word]);

  const fetchMyLearnings = async () => {
    setIsLoading(true);
    await getMyLearnings(currentUser.userId)
      .then(({ data }: any) => {
        if (data.errorMsg === "success") {
          const res = data.data.filter((item: any) => {
            if (activeTab === 0) {
              return (
                item.type === "Insession" &&
                item.skill.toLowerCase().includes(word.toLowerCase())
              );
            } else if (activeTab === 1) {
              return (
                item.type === "Upcoming" &&
                item.skill.toLowerCase().includes(word.toLowerCase())
              );
            } else if (activeTab === 2) {
              return (
                item.type === "Past" &&
                item.skill.toLowerCase().includes(word.toLowerCase())
              );
            }
          });
          setData(res);
        } else {
          setHasError(true);
        }
        setIsLoading(false);
      })
      .catch((_) => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  return (
    <div className="mylearning-wrapper">
      <div className="tabs-wrapper">
        <div className="input-wrapper">
          <input
            value={word}
            onChange={(e) => setWord(e.target.value)}
            type="text"
            placeholder={locale.myLearningFindClass[currentLanguage]}
          />
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
        <Tabs
          activeTab={activeTab}
          onChange={(tabId) => setActiveTab(tabId)}
          ripple
          className="tabs"
        >
          <Tab className={`${activeTab === 0 && "active"}`}>
            {locale.myLearningSession[currentLanguage]}
          </Tab>
          <Tab className={`${activeTab === 1 && "active"}`}>
            {locale.myLearningUpcoming[currentLanguage]}
          </Tab>
          <Tab className={`${activeTab === 2 && "active"}`}>
            {locale.myLearningPast[currentLanguage]}
          </Tab>
        </Tabs>
      </div>

      <section>
        <h6>
          {data.length > 0 &&
            data.length + ` classroom${data.length > 1 ? "s" : ""}`}
        </h6>
        <div className="content">
          <div className="content-header">
            <div className="header-item-title">
              {locale.myLearningCourseName[currentLanguage]}
            </div>
            {/* <div className="header-item">Location</div>
            <div className="header-item">Type</div> */}
            <div className="header-item">
              {locale.myLearningStartDate[currentLanguage]}
            </div>
            <div className="header-item">
              {locale.myLearningEndDate[currentLanguage]}
            </div>
            {/* <div className="header-item">Students</div>
            <div className="header-item">Tags</div> */}
            <div className="header-item-delete"></div>
          </div>
          <div className="content-body">
            {isLoading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#3842DC"
                className="loading"
                width="38px"
                height="38px"
              />
            ) : hasError ? (
              <div className="error-wrapper">
                <h5>{locale.myLearningError[currentLanguage]}</h5>
                <i className="fa fa-refresh fa-2x" aria-hidden="true"></i>
              </div>
            ) : data.length === 0 ? (
              <div className="course-not-found">
                <i className="fa fa-frown-o fa-4x" aria-hidden="true"></i>
                <h5>{locale.myLearningNotRegister[currentLanguage]}</h5>
                <button onClick={() => history.push("/search")}>
                  {locale.myLearningGoToCourses[currentLanguage]}
                </button>
              </div>
            ) : (
              data.map((item: any, index: any) => (
                <div key={index} className="body-row">
                  <div className="body-item-title">{item.skill}</div>
                  {/* <div className="body-item">{item.timeZone}</div>
                  <div className="body-item">{item.type}</div> */}
                  <div className="body-item">
                    {moment(item.startDate).format("MMM DD, YYYY h:mm A")}
                  </div>
                  <div className="body-item">
                    {moment(item.endDate).format("MMM DD, YYYY h:mm A")}
                  </div>
                  {/* <div className="body-item">{`10`}</div>
                  <div className="body-item">{`Direct, 22WEB012002,ql-rpsconsulting-0`}</div>
                  <div className="body-item-delete">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </div> */}
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyLearning;
