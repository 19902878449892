import React, { Fragment, useState } from "react";
import Footer from "./CommonContainer/Footer";
import Header from "./CommonContainer/Header";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Drawer from "react-modern-drawer";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";
import { GA_TRACKING_ID, MAIN_URL } from "../config/constants";
import partner_logo from "../assets/images/partner_logo.png";
import { locale } from "../utils/locale";

import dashboard from "../assets/images/dashboard.png";
import reading from "../assets/images/reading.png";

ReactGA.initialize(GA_TRACKING_ID);

const LayoutContainer = ({ children }: any) => {
  const history = useHistory();
  const { currentLanguage } = useSelector((state: any) => state);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  ReactGA.pageview(MAIN_URL + pathname);

  const toggleDrawer = () => setIsOpen((prevState) => !prevState);

  return (
    <div className="layout-wrapper">
      <Header />
      {children}
      {!pathname.includes("/search") && <Footer />}
      {(pathname === "/dashboard" || pathname === "/mylearning") && (
        <>
          <div className="left-float-actions">
            <i
              className="fa fa-bars fa-lg"
              aria-hidden="true"
              onClick={toggleDrawer}
            ></i>
          </div>
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="left"
            className="drawer-wrapper"
          >
            <div className="drawer-body">
              {/* <div className="navbar-brand">
              <a href="/">APT</a>
            </div> */}
              <div className="menu-wrapper">
                <div
                  className={`menu-item ${
                    pathname === "/dashboard" && "active-menu"
                  }`}
                  onClick={() => {
                    history.push("/dashboard");
                    toggleDrawer();
                  }}
                >
                  <img src={dashboard} alt="" />
                  {locale.dashboard[currentLanguage]}
                </div>
                <div
                  className={`menu-item ${
                    pathname === "/mylearning" && "active-menu"
                  }`}
                  onClick={() => {
                    history.push("/mylearning");
                    toggleDrawer();
                  }}
                >
                  <img src={reading} alt="" />
                  {locale.myLearning[currentLanguage]}
                </div>
              </div>
            </div>
            <i
              className="fa fa-times-circle fa-lg btn-close"
              aria-hidden="true"
              onClick={toggleDrawer}
            ></i>
          </Drawer>
        </>
      )}
      {pathname === "/" && (
        <Fragment>
          {/*<ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCH_KEY || ""}
            size="invisible"
          />*/}
          <div className="partner-wrapper">
            <img
              className="partner-logo"
              src={partner_logo}
              alt="APT Microsoft Partner"
            />
          </div>
        </Fragment>
      )}
      {/* {pathname !== "/review" && (
        <div className="fab-btn" onClick={() => history.push("/review")}>
        <i className="fa fa-paper-plane" aria-hidden="true"></i>
        <p>Review</p>
        </div>
      )} */}
    </div>
  );
};

export default LayoutContainer;
