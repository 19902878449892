import React, { useState } from "react";
import { useSelector } from "react-redux";
import { locale } from "../../utils/locale";
import { useHistory } from "react-router-dom";
import mobile_bg from "../../assets/images/mobile.png";
import bg_video from "../../assets/videos/bg_video.mp4";
import { isMobile } from "react-device-detect";
import { tada } from "react-animations";
import { StyleSheet, css } from "aphrodite";

const OverView = () => {
  const history = useHistory();
  const [searchWord, setSearchWord] = useState("");
  const currentLang = useSelector((state: any) => state.currentLanguage);
  const styles = StyleSheet.create({
    fadeInUp: {
      animationName: tada,
      animationDuration: "1s",
    },
  });
  const handleSearch = () => {
    history.push({
      pathname: "/search",
      search: `?word=${searchWord}`,
    });
  };

  return (
    <section
      style={{
        position: "relative",
        width: "100vw",
        height: `${isMobile ? "80vh" : "100vh"}`,
      }}
    >
      <video
        autoPlay
        loop
        muted
        poster={mobile_bg}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: "50%",
          left: "50%",
          objectFit: "cover",
          transform: "translate(-50%,-50%)",
          zIndex: -1,
        }}
      >
        <source src={bg_video + "#t=30"} type="video/mp4" />
      </video>
      <div className="titan-caption">
        <div className="caption-content">
          <div
            className={`title font-alt mb-40 titan-title-size-4 ${css(
              styles.fadeInUp
            )}`}
            style={{ color: "#ffffff" }}
          >
            {locale.getCertifiedMoto[currentLang]}
          </div>
          <div className="home-search-container">
            <div className="input-container">
              <input
                value={searchWord}
                type="text"
                placeholder="Data Science, AI, Finance, Marketing, Microsoft courses, SQL, Excel ..."
                onChange={(event) => {
                  setSearchWord(event.target.value);
                }}
                onKeyPress={(event) => {
                  event.key === "Enter" && handleSearch();
                }}
              />
              {searchWord ? (
                <i
                  onClick={() => setSearchWord("")}
                  id="delete"
                  className="fa fa-times fa-lg"
                  aria-hidden="true"
                ></i>
              ) : (
                <i className="fa fa-search fa-lg" aria-hidden="true"></i>
              )}
            </div>
            <button
              className="section-scroll btn btn-border-w btn-round"
              onClick={handleSearch}
            >
              {locale.searchTraining[currentLang]}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverView;
