import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import Home from "./components/HomeContainer/Home";
import SearchTrainings from "./components/searchContainer/SearchTrainings";
import NotFound from "./components/PathNotFound";
import Cart from "./components/CartContainer/Cart";
import { ToastContainer } from "react-toastify";
import Dashboard from "./components/DashboardContainer/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import LayoutContainer from "./components/LayoutContainer";
import Review from "./components/Review";
import MyLearning from "./components/DashboardContainer/MyLearning";

export default ({ msalInstance }: any) => {
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <LayoutContainer>
          <div className="page-loader">
            <div className="loader">Loading...</div>
          </div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={SearchTrainings} />
            <Route exact path="/cart" component={Cart} />
            <Route
              exact
              path="/dashboard"
              render={() => (
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              )}
            />
            <Route
              exact
              path="/mylearning"
              render={() => (
                <ProtectedRoute>
                  <MyLearning />
                </ProtectedRoute>
              )}
            />
            <Route exact path="/review" component={Review} />
            <Route path="*" component={NotFound} />
          </Switch>
        </LayoutContainer>
        <ToastContainer />
      </BrowserRouter>
    </MsalProvider>
  );
};
