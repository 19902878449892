import React, { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { useDispatch } from "react-redux";
import { addUser } from "../store/actions";
import { saveUserInfo } from "../config/api";
import { toast } from "react-toastify";
import nouser from "../assets/images/nouser.png";
import { useSelector } from "react-redux";

const Review = () => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [wordCount, setWordCount] = useState(0);
  const [message, setMessage] = useState("");
  const [isMaxCount, setIsMaxCount] = useState(false);
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const currentUser = useSelector((state: any) => state.currentUser);

  useEffect(() => window.scroll(0, 0), []);

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then(({ account: { name, username, localAccountId } }: any) => {
        dispatch(
          addUser({
            fullname: name,
            email: username,
            userId: localAccountId,
          })
        );
        toast("Signed in successfully!", {
          position: "bottom-center",
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
        //saveUserInfo(name, localAccountId);
      });
  };

  const handleMessage = ({ target }: any) => {
    setMessage(target.value);
    setWordCount(target.value.length);
    if (target.value.length <= 1000) {
      setIsMaxCount(false);
    } else {
      setIsMaxCount(true);
    }
  };

  return (
    <div className="review-wrapper">
      <UnauthenticatedTemplate>
        <div className="unauth">
          <p>
            Dear Reviewer, please sign in to submit a new review for us. Your
            time is most valued, so we will keep it short and simple.
          </p>
          <button onClick={handleLogin}>
            please use Azure B2C to Sign in and submit feedback
          </button>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div className="auth">
          <div className="auth-left">
            {/* <img src={nouser} /> */}
            <div className="user-info">
              <h6>{currentUser.fullname}</h6>
              <h6>Designation at {designation}</h6>
              <h6>Company {company}</h6>
            </div>
          </div>
          <div className="auth-right">
            <textarea value={message} onChange={handleMessage}></textarea>
            <button>
              <i className="fa fa-paper-plane" aria-hidden="true"></i> Submit
            </button>
            <p>
              {wordCount} / 1000
              {isMaxCount && <span>maximum charachter count reached</span>}
            </p>
          </div>
        </div>
      </AuthenticatedTemplate>
    </div>
  );
};

export default Review;
