import React from "react";
import { locale } from "../../utils/locale";
import partner_logo from "../../assets/images/partner_logo.png";
import { useSelector } from "react-redux";

const Quote = () => {
  const currentLang = useSelector((state: any) => state.currentLanguage);

  return (
    <section className="module-extra-small bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-8 col-lg-7 col-lg-offset-2">
            <div className="callout-text font-alt">
              <h4>{locale.lookingPartner[currentLang]}</h4>
              <p style={{ marginBottom: "0" }}>
                {locale.lookingPartner1[currentLang]}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="callout-btn-box">
              <img src={partner_logo} alt="APT Microsoft Partner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
