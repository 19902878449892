export const locale: any = {
  verifyCertificate: {
    us: "Verify Certificate",
    gb: "Verify Certificate",
    fr: "Vérifier le certificat",
  },
  verifyCertificateSub1: {
    us: "Verification Tool",
    gb: "Verification Tool",
    fr: "Outil de vérification",
  },
  verifyCertificateSub2: {
    gb: "What is APT's Blockchain Enabled Certification Tool?",
    us: "What is APT's Blockchain Enabled Certification Tool?",
    fr: "Qu'est-ce que l'outil de certification Blockchain Enabled d'APT?",
  },
  learningPaths: {
    us: "Learning Paths",
    gb: "Learning Paths",
    fr: "Parcours d'apprentissage",
  },
  learningPaths1: {
    us: "Accounting & Finance",
    gb: "Accounting & Finance",
    fr: "Comptabilité et Finance",
  },
  learningPaths2: {
    us: "Analytics",
    gb: "Analytics",
    fr: "Analytique",
  },
  learningPaths3: {
    us: "Artificial Intelligence",
    gb: "Artificial Intelligence",
    fr: "Intelligence artificielle",
  },
  learningPaths4: {
    us: "Cloud Developer",
    gb: "Cloud Developer",
    fr: "Développeur infonuagique",
  },
  learningPaths5: {
    us: "Cloud DevOps",
    gb: "Cloud DevOps",
    fr: "DevOps Cloud",
  },
  learningPaths6: {
    us: "Data Engineering",
    gb: "Data Engineering",
    fr: "Ingénierie des données",
  },
  learningPaths7: {
    us: "Data Science",
    gb: "Data Science",
    fr: "Science des données",
  },
  learningPaths8: {
    us: "Manufacturing & Supply Chain",
    gb: "Manufacturing & Supply Chain",
    fr: "Fabrication et chaîne d'approvisionnement",
  },
  learningPaths9: {
    us: "Marketing",
    gb: "Marketing",
    fr: "Commercialisation",
  },
  learningPaths10: {
    us: "Operations",
    gb: "Operations",
    fr: "Opérations",
  },
  learningPaths11: {
    us: "Web Developer",
    gb: "Web Developer",
    fr: "Développeur web",
  },
  learningPaths12: {
    us: "Security",
    gb: "Security",
    fr: "Sécurité",
  },
  byProducts: {
    us: "By Products",
    gb: "By Products",
    fr: "Par Produits",
  },
  aboutAPT: {
    us: "About APT",
    gb: "About APT",
    fr: "Sur APT",
  },
  aboutAPT1: {
    us: "Request a Callback",
    gb: "Request a Callback",
    fr: "Demander un rappel",
  },
  aboutAPT2: {
    us: "Contact Us",
    gb: "Contact Us",
    fr: "Nous contacter",
  },
  aboutAPT3: {
    us: "About Us",
    gb: "About Us",
    fr: "À propos de nous",
  },
  creatAccount: {
    us: "Create Account",
    gb: "Create Account",
    fr: "Créer un compte",
  },
  signInAccount: {
    us: "Account",
    gb: "Account",
    fr: "Compte",
  },
  signIn: {
    us: "Sign In",
    gb: "Sign In",
    fr: "S'identifier",
  },
  signIn1: {
    us: "Create Account",
    gb: "Create Account",
    fr: "Créer un compte",
  },
  signIn2: {
    us: "Dashboard",
    gb: "Dashboard",
    fr: "Tableau De Bord",
  },
  signIn3: {
    us: "Sign Out",
    gb: "Sign Out",
    fr: "Se déconnecter",
  },
  getCertifiedMoto: {
    us: "Get Certified, Stand Out In The Crowd",
    gb: "Get Certified, Stand Out In The Crowd",
    fr: "Obtenez une certification, démarquez-vous de la foule",
  },
  searchTraining: {
    us: "Search Training",
    gb: "Search Training",
    fr: "Rechercher une formation",
  },
  lookingPartner: {
    us: "looking for a certified Learning Partner?",
    gb: "looking for a certified Learning Partner?",
    fr: "à la recherche d'un partenaire d'apprentissage certifié ?",
  },
  lookingPartner1: {
    us: "We are always here for your organizations and teams",
    gb: "We are always here for your organizations and teams",
    fr: "Nous sommes toujours là pour vos organisations et vos équipes",
  },
  getQuote: {
    us: "get a quote",
    gb: "get a quote",
    fr: "obtenir un devis",
  },
  practiceArea: {
    us: "Practice Areas",
    gb: "Practice Areas",
    fr: "Domaines de pratique",
  },
  practiceAreaDetail1: {
    us: "Being able to consume, operate, secure and monitor intelligent services",
    gb: "Being able to consume, operate, secure and monitor intelligent services",
    fr: "Pouvoir consommer, exploiter, sécuriser et surveiller des services intelligents",
  },
  practiceAreaDetail2: {
    us: "Deep dive into practical Statistical and Deep Learning with deployment and MLOps",
    gb: "Deep dive into practical Statistical and Deep Learning with deployment and MLOps",
    fr: "Plongez dans l'apprentissage statistique et profond pratique avec déploiement et MLOps",
  },
  practiceAreaDetail3: {
    us: "Specialized SQL Server, Synapse Analytics, BigQuery, BigTable and MongoDB trainings",
    gb: "Specialized SQL Server, Synapse Analytics, BigQuery, BigTable and MongoDB trainings",
    fr: "Formations spécialisées SQL Server, Synapse Analytics, BigQuery, BigTable et MongoDB",
  },
  practiceAreaDetail4: {
    us: "From managing data lakes to warehousing, OLAP deals with building ETL/ELT pipelines",
    gb: "From managing data lakes to warehousing, OLAP deals with building ETL/ELT pipelines",
    fr: "De la gestion des lacs de données à l'entreposage, OLAP s'occupe de la construction de pipelines ETL/ELT",
  },
  practiceAreaDetail5: {
    us: "Being able to collect, accumulate, manage device and data centrally from range of sensors and 'things'",
    gb: "Being able to collect, accumulate, manage device and data centrally from range of sensors and 'things'",
    fr: "Être capable de collecter, d'accumuler, de gérer les appareils et les données de manière centralisée à partir d'une gamme de capteurs et de \"choses\"",
  },
  practiceAreaDetail6: {
    us: "Developer, Infrastructure, Administrator, Security and other advanced trainings",
    gb: "Developer, Infrastructure, Administrator, Security and other advanced trainings",
    fr: "Développeur, Infrastructure, Administrateur, Sécurité et autres formations avancées",
  },
  clients: {
    us: "TESTIMONIALS",
    gb: "TESTIMONIALS",
    fr: "TÉMOIGNAGES",
  },
  statistics1: {
    us: "Successful Trainings",
    gb: "Successful Trainings",
    fr: "Formations réussies",
  },
  statistics2: {
    us: "Learning Partners",
    gb: "Learning Partners",
    fr: "Partenaires d'apprentissage",
  },
  statistics3: {
    us: "People Trained",
    gb: "People Trained",
    fr: "Personnes formées",
  },
  statistics4: {
    us: "Certified Trainers",
    gb: "Certified Trainers",
    fr: "Formateurs certifiés",
  },
  bookSeat: {
    us: "Book My Seat Now",
    gb: "Book My Seat Now",
    fr: "Réserver ma place maintenant",
  },
  requestTitle: {
    us: "Request a call back",
    gb: "Request a call back",
    fr: "Demander à être rappelé",
  },
  requestDetail: {
    us: `Would you like to speak to one of our account managers over
      the phone for group bookings or clarifications? Just submit
      your details and we’ll be in touch shortly. You can also 
      <a href="mailto:training@consultingapt.com">email</a> us if you
      would prefer.`,
    gb: `Would you like to speak to one of our account managers over
      the phone for group bookings or clarifications? Just submit
      your details and we’ll be in touch shortly. You can also 
      <a href="mailto:training@consultingapt.com">email</a> us if you
      would prefer.`,
    fr: `Souhaitez-vous parler à l'un de nos gestionnaires de compte au cours de
            le téléphone pour les réservations de groupe ou les clarifications ? Soumettez simplement
            vos coordonnées et nous vous contacterons sous peu.Vous pouvez également nous envoyer un <a href="mailto:training@consultingapt.com">email</a> si vous préférez.`,
  },
  requestPlaceholderName: {
    us: "Name",
    gb: "Name",
    fr: "Nom",
  },
  requestPlaceholderPhone: {
    us: "Phone Number",
    gb: "Phone Number",
    fr: "Numéro de téléphone",
  },
  requestButton: {
    us: "Give me a Call",
    gb: "Give me a Call",
    fr: "Appelle Moi",
  },
  requestSendingButton: {
    us: "Sending Request...",
    gb: "Sending Request...",
    fr: "Enviando pedido...",
  },
  feedbackTitle: {
    us: "Get in touch",
    gb: "Get in touch",
    fr: "Entrer en contact",
  },
  feedbackButtonFloat: {
    us: "FEED BACK",
    gb: "FEED BACK",
    fr: "Retour D'information",
  },
  feedbackInput1: {
    us: "Name",
    gb: "Name",
    fr: "Nom",
  },
  feedbackInputPlaceholder1: {
    us: "Your Name",
    gb: "Your Name",
    fr: "Votre Nom",
  },
  feedbackInput2: {
    us: "Email",
    gb: "Email",
    fr: "E-mail",
  },
  feedbackInputPlaceholder2: {
    us: "Your Email",
    gb: "Your Email",
    fr: "Votre E-mail",
  },
  feedbackInputPlaceholder3: {
    us: "Your Message",
    gb: "Your Message",
    fr: "Votre Message",
  },
  feedbackButton: {
    us: "Submit",
    gb: "Submit",
    fr: "Nous faire parvenir",
  },
  feedbackButtonSend: {
    us: "Sending...",
    gb: "Sending...",
    fr: "Envoi en cours...",
  },
  footerTitle1: {
    us: "About APT",
    gb: "About APT",
    fr: "Sur APT",
  },
  footerTitle11: {
    us: "Who are we?",
    gb: "Who are we?",
    fr: "Qui sommes nous?",
  },
  footerTitle12: {
    us: "Our Team",
    gb: "Our Team",
    fr: "Notre équipe",
  },
  footerTitle13: {
    us: "Email",
    gb: "Email",
    fr: "E-mail",
  },
  footerTitle14: {
    us: "Phone",
    gb: "Phone",
    fr: "Téléphoner",
  },
  footerTitle2: {
    us: "Top Certifications",
    gb: "Top Certifications",
    fr: "Meilleures Certifications",
  },
  footerTitle3: {
    us: "Learning Paths",
    gb: "Learning Paths",
    fr: "Parcours D'apprentissage",
  },
  footerTitle4: {
    us: "Top Courses",
    gb: "Top Courses",
    fr: "Meilleurs Cours",
  },
  footerRight: {
    us: "All Rights Reserved",
    gb: "All Rights Reserved",
    fr: "Tous Les Droits Sont Réservés",
  },
  searchFilterBy: {
    us: "Filter By",
    gb: "Filter By",
    fr: "Filtrer Par",
  },
  searchFilterSort: {
    us: "Filter & Sort",
    gb: "Filter & Sort",
    fr: "Filtre & Sorte",
  },
  searchBookNow: {
    us: "Book Now",
    gb: "Book Now",
    fr: "Reserve Maintenant",
  },
  searchBulkBook: {
    us: "Bulk Booking",
    gb: "Bulk Booking",
    fr: "Réservation Groupée",
  },
  searchNextTraining: {
    us: "Next Training On",
    gb: "Next Training On",
    fr: "Prochaine Formation activée",
  },
  searchNextTrainingBookNow: {
    us: "Book Now",
    gb: "Book Now",
    fr: "Reserve Maintenant",
  },
  searchCallender: {
    us: "Show Calender",
    gb: "Show Calender",
    fr: "Afficher Le Calendrier",
  },
  searchViewContent: {
    us: "View Content",
    gb: "View Content",
    fr: "Afficher Le Contenu",
  },
  searchOfficalSite: {
    us: "Go to Offical Site",
    gb: "Go to Offical Site",
    fr: "Aller au Site Officiel",
  },
  searchAddCart: {
    us: "Add to Cart",
    gb: "Add to Cart",
    fr: "Ajouter tout au Chariot",
  },
  searchAddedToCart: {
    us: "On Cart",
    gb: "On Cart",
    fr: "Chariot",
  },
  searchClear: {
    us: "Clear",
    gb: "Clear",
    fr: "Dégager",
  },
  searchBack: {
    us: "Back",
    gb: "Back",
    fr: "Arrière",
  },
  searchSkillsGained: {
    us: "Skills Gained",
    gb: "Skills Gained",
    fr: "Compétences Acquises",
  },
  searchCertificate: {
    us: "Certificate Earned",
    gb: "Certificate Earned",
    fr: "Certificat Gagné",
  },
  searchDescription: {
    us: "Description",
    gb: "Description",
    fr: "La Description",
  },
  searchAudience: {
    us: "Audience",
    gb: "Audience",
    fr: "Public",
  },
  searchObjectives: {
    us: "Learning Objectives",
    gb: "Learning Objectives",
    fr: "Objectifs D'apprentissage",
  },
  searchRequisite: {
    us: "Prerequisities",
    gb: "Prerequisities",
    fr: "Conditions Préalables",
  },
  searchCourseCode: {
    us: "Course Code",
    gb: "Course Code",
    fr: "Code De Cours",
  },
  searchEvent: {
    us: "Events",
    gb: "Events",
    fr: "Événements",
  },
  searchNoEvents: {
    us: "No events found!",
    gb: "No events found!",
    fr: "Aucun événement trouvé !",
  },
  dashboard: {
    us: "Dashboard",
    gb: "Dashboard",
    fr: "Tableau De Bord",
  },
  dashboardTitle: {
    us: "Jumpstart your cloud career",
    gb: "Jumpstart your cloud career",
    fr: "Lancez votre carrière dans le cloud",
  },
  dashboardDescription: {
    us: "Not sure where to start? Find featured learning below. We give you temporary credentials to actual cloud resources, so you can learn the cloud using the real thing.",
    gb: "Not sure where to start? Find featured learning below. We give you temporary credentials to actual cloud resources, so you can learn the cloud using the real thing.",
    fr: "Vous ne savez pas par où commencer ? Trouvez l'apprentissage en vedette ci-dessous. Nous vous donnons des informations d'identification temporaires pour les ressources cloud réelles, afin que vous puissiez apprendre le cloud en utilisant la vraie chose.",
  },
  dashboardInprogress: {
    us: "In Progress",
    gb: "In Progress",
    fr: "En Cours",
  },
  dashboardTotal: {
    us: "Total Trainings",
    gb: "Total Trainings",
    fr: "Formation Rotale",
  },
  dashboardCompleted: {
    us: "Completed Trainings",
    gb: "Completed Trainings",
    fr: "Formations Terminées",
  },
  dashboardUpcoming: {
    us: "Upcoming Trainings",
    gb: "Upcoming Trainings",
    fr: "Formations à Venir",
  },
  dashboardTranscript: {
    us: "Transcript",
    gb: "Transcript",
    fr: "Transcription",
  },
  dashboardSchedule: {
    us: "Scheduled Course",
    gb: "Scheduled Course",
    fr: "Cours Programmé",
  },
  dashboardViewCourse: {
    us: "View All Courses",
    gb: "View All Courses",
    fr: "Afficher Tous Les Cours",
  },
  myLearning: {
    us: "My Learning",
    gb: "My Learning",
    fr: "Mon Apprentissage",
  },
  myLearningFindClass: {
    us: "Find A Classroom",
    gb: "Find A Classroom",
    fr: "Trouver une salle de classe",
  },
  myLearningSession: {
    us: "In Session",
    gb: "In Session",
    fr: "En séance",
  },
  myLearningUpcoming: {
    us: "Upcoming",
    gb: "Upcoming",
    fr: "A venir",
  },
  myLearningPast: {
    us: "Past",
    gb: "Past",
    fr: "Passé",
  },
  myLearningCourseName: {
    us: "Course Name",
    gb: "Course Name",
    fr: "Nom du cours",
  },
  myLearningStartDate: {
    us: "Start Date",
    gb: "Start Date",
    fr: "Date de début",
  },
  myLearningEndDate: {
    us: "End Date",
    gb: "End Date",
    fr: "Date de fin",
  },
  myLearningError: {
    us: "Error, Try it again!",
    gb: "Error, Try it again!",
    fr: "Erreur, Réessayez!",
  },
  myLearningNotRegister: {
    us: "You haven't registered any course!",
    gb: "You haven't registered any course!",
    fr: "Vous n'êtes inscrit à aucun cours !",
  },
  myLearningGoToCourses: {
    us: "Go To Courses",
    gb: "Go To Courses",
    fr: "Aller aux cours",
  },
  trainingDetailCalender: {
    us: "Calender",
    gb: "Calender",
    fr: "Calendrier",
  },
  trainingDetailTimezone: {
    us: "Time Zone (GMT)",
    gb: "Time Zone (GMT)",
    fr: "Fuseau Horaire (GMT)",
  },
  trainingDetailPrice: {
    us: "Price GBP(£)",
    gb: "Price GBP(£)",
    fr: "Prix GBP(£)",
  },
  trainingDetailGotoTraining: {
    us: "Go To My Training",
    gb: "Go To My Training",
    fr: "Accéder à ma formation",
  },
};
