import { AUTH } from "../config/constants";

export const b2cPolicies: any = {
  names: {
    signUpSignIn: AUTH.AAD_B2C_USER_FLOW_SIGNUPSIGNIN,
    forgotPassword: AUTH.AAD_B2C_USER_FLOW_RESET_PASSWORD,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${AUTH.AAD_B2C_Name}.b2clogin.com/${AUTH.AAD_B2C_Name}.onmicrosoft.com/${AUTH.AAD_B2C_USER_FLOW_SIGNUPSIGNIN}`,
    },
    forgotPassword: {
      authority: `https://${AUTH.AAD_B2C_Name}.b2clogin.com/${AUTH.AAD_B2C_Name}.onmicrosoft.com/${AUTH.AAD_B2C_USER_FLOW_RESET_PASSWORD}`,
    },
  },
  authorityDomain: `${AUTH.AAD_B2C_Name}.b2clogin.com`,
};

export const msalConfig: any = {
  auth: {
    clientId: AUTH.AAD_B2C_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: any = {
  scopes: [],
};

export const forgotPasswordRequest: any = {
  authority: `https://${AUTH.AAD_B2C_Name}.b2clogin.com/${AUTH.AAD_B2C_Name}.onmicrosoft.com/${AUTH.AAD_B2C_USER_FLOW_RESET_PASSWORD}`,
};
